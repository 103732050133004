<template>
  <div>
  <h6 class="text-left text-dark font-weight-semi-bold font-primary mt-3">
    PayPal Account 
  </h6>
  <b-container>
    <b-row
      class="d-flex flex-row align-items-center justify-content-center w-100"
      no-gutters
    >
      <b-col
        class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn"
        cols="12"
      >
        <FormButton
          variant="primary"
          isBlock
          class="font-weight-normal text-white mt-2 border-dark"
          type="submit"
          @click.native="connectPayPal"
          v-if="!gatewayOptions.onboardingCompleted"
          :isDisabled="
            gatewayOptions.onboardingCompleted ||
            this.forceDisable ||
            gatewayOptions.onboardingInProgress
          "
          :isLoading="gatewayOptions == null || onboardLoading"
          >Connect PayPal</FormButton
        >
        <FormButton
          variant="primary"
          isBlock
          class="font-weight-normal text-white mt-2 border-dark"
          type="submit"
          @click.native="disconnectArea"
          v-else
          :isLoading="gatewayOptions == null || onboardLoading"
          >Disconnect PayPal</FormButton
        >
      </b-col>
    </b-row>
    <b-row v-if="showDisconnectDirections">
      <hr />
      <div v-if="!gatewayOptions.disconnected">
        <b-alert class="m-4" show variant="light">
          <h5>Disclaimer</h5>
          Disconnecting your PayPal account will prevent you from offering
          PayPal services and products on your website. Do you wish to continue?

          <b-row v-if="!disconnected" class="mt-4">
            <b-col>
              <FormButton
                variant="light"
                isBlock
                class="font-weight-normal text-black mt-2 border-dark"
                type="submit"
                @click.native="disconnectArea"
                :isLoading="gatewayOptions == null || onboardLoading"
                >No</FormButton
              >
            </b-col>
            <b-col>
              <FormButton
                variant="danger"
                isBlock
                class="font-weight-normal text-white mt-2 border-dark"
                type="submit"
                @click.native="disconnectPayPal"
                :isLoading="loadingDisconnect"
                >Yes</FormButton
              >
            </b-col>
          </b-row>
          <div v-else>
            <b-alert show class="mt-4" variant="success">
              PayPal disconnected successfully.
            </b-alert>
          </div>
        </b-alert>
      </div>

      <b-alert class="m-4" show variant="warning">
        <div>
          <h5>Directions for disconnecting a PayPal account</h5>
          <ul>
            <li>
              Login to your PayPal account and click on your icon in the
              top-right corner
            </li>
            <li>Under "Account Settings" select "API Access"</li>
            <li>
              Under REST API Integration, select "Manage REST API apps and
              credentials"
            </li>
            <li>
              Select the app you would like to revoke and remove permissions
              from the platform
            </li>
          </ul>
        </div>
      </b-alert>
    </b-row>
    <b-row>
      <b-alert
        v-for="(message, index) in messages"
        :key="index"
        class="m-4"
        show
        :variant="message.type"
      >
        <div v-html="message.html"></div>
      </b-alert>
    </b-row>
  </b-container>
</div>
</template>

<script>
import FormButton from "@/components/Form/FormButton";
import {
  SetupStripe,
  GetOnboardingStatus,
  DisconnectPayPal,
  // LoginStripe
} from "@/services/settings.service";
export default {
  name: "PayPalAccount",
  components: {
    FormButton,
  },
  data() {
    return {
      onboardLoading: false,
      messages: [],
      forceMsg: false,
      showDisconnectDirections: false,
      disconnected: false,
      loadingDisconnect: false,
    };
  },
  props: {
    gatewayOptions: {
      type: Object,
      default: null,
    },
    campaign: {
      type: Object,
      default: null,
    },
    forceDisable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.gatewayOptions.onboardingInProgress) this.listenEvents();
    if (this.campaign.paypalOnboardingStatus) {
      for (const message of this.campaign.paypalOnboardingStatus) {
        if (typeof message == "string") {
          this.messages.push({
            html: message,
            type: "success",
          });
        } else {
          this.messages.push(message);
        }
      }
      this.forceMsg = true;
    }
  },
  computed: {},
  methods: {
    connectPayPal() {
      this.onboardLoading = true;
      SetupStripe()
        .then((res) => {
          // window.location.replace(res.data.content.redirect);
          let link = res.data.content.redirect;
          this.listenEvents();
          window.location.href = link;
          // window.open(link, "_blank");
        })
        .catch(() => {
          console.log("ERROR PAYACC_PP_01");
        });
    },
    disconnectArea() {
      this.showDisconnectDirections = !this.showDisconnectDirections;
    },
    async disconnectPayPal() {
      this.loadingDisconnect = true;
      const res = await DisconnectPayPal();
      if (res.status == 200) {
        this.disconnected = res.data.content.disconnected;
      }
      this.loadingDisconnect = false;
    },
    async listenEvents() {
      try {
        const res = await GetOnboardingStatus();
        const status = res.data.content;
        if (status.connected) {
          this.gatewayOptions.onboardingCompleted = true;
          this.messages = [];
          if (status.paypalOnboardingStatus) {
            for (const message of status.paypalOnboardingStatus) {
              if (typeof message == "string") {
                this.messages.push({
                  html: message,
                  type: "success",
                });
              } else {
                this.messages.push(message);
              }
            }
          } else {
            this.messages.push({
              html: "PayPal connected successfully.",
              type: "success",
            });
          }
          this.onboardLoading = false;
          this.gatewayOptions.disconnected = false;
        } else {
          this.message = res.data.content.status;
          this.forceDisable = true;
          this.forceMsg = true;
          this.onboardLoading = false;
          await this.listenEvents();
        }
      } catch {
        await this.listenEvents();
      }
    },
  },
};
</script>

<style scoped>
.setUpBtn {
  margin: 2px;
}
</style>
