<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-center w-100 mt-3"
  >
    <b-form-group class="text-center" v-slot="{ ariaDescribedby }" name="name">
      <b-form-radio-group
        v-model="selectedOption"
        :options="options"
        :aria-describedby="ariaDescribedby"
        class="custom-checkbox"
        type="checkbox"
        :disabled="disabled"
      ></b-form-radio-group>
    </b-form-group>
  </b-row>
</template>

<script>
export default {
  name: "FormRadioButton",

  props: {
    name: String,
    options: {
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      default: null,
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style>
.custom-checkbox >>> .custom-control-label::before {
  border-color: var(--light-gray);
}

.custom-checkbox
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.custom-checkbox
  >>> .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--white);
  border-color: var(--light-gray);
}
</style>
