<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-12 w-full py-3 sm:py-6">
    <div id="payment"></div>
    <div class="card_container">
      <form
        id="card-form"
        action=""
        class="grid grid-cols-8 gap-4 px-3 md:px-0 mt-8 mb-6 sm:mb-0 w-full"
      >
        <div class="col-span-8 sm:col-span-8 mb-2">
          <label
            class="text-xs font-semibold text-prime-gray"
            for="card-holder-name"
            >Card Holder Name</label
          >
          <!-- <input
            id="card-holder-name"
            placeholder="Name on card"
            class="
              card_field
              block
              w-100
              h-1
              mt-1
              px-3
              rounded-sm
              font-body
              sm:text-sm
              border
              focus:ring-secondary focus:border-secondary
              transition-colors
              placeholder-prime-gray
              shadow-soft
            "
          />
          {{ this.nameError }}-->

          <FormInput
            groupId="card-holder-name"
            id="card-holder-name"
            class="text-prime-gray"
            :state="cardHolderNameError.status"
            :invalidFeedback="`${cardHolderNameError.message}`"
            label="Name on card"
            v-model="Nameoncard"
            isRequired
            trim
            form="admin-registration-form"
            type="text"
          ></FormInput>
        </div>

        <div class="mb-2">
          <label class="text-xs font-semibold text-prime-gray" for="card-number"
            >Card Number</label
          >

          <div
            id="card-number"
            class="card_field block w-100 h-1 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
          ></div>
          <div class="invalid-feedback">Credit card number is required</div>
        </div>
        <div class="col-span-8 sm:col-span-4 mb-2">
          <div>
            <label
              class="text-xs font-semibold text-prime-gray"
              for="expiration-date"
              >Expiration Date</label
            >

            <div
              id="expiration-date"
              class="card_field block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
            ></div>
            <div class="invalid-feedback">Expiration date required</div>
          </div>
        </div>
        <div class="col-span-8 sm:col-span-4 mb-5">
          <div>
            <label class="text-xs font-semibold text-prime-gray" for="cvv"
              >CVV</label
            >
            <div
              id="cvv"
              class="card_field block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
            ></div>
            <div class="invalid-feedback">Security code required</div>
          </div>
        </div>

        <div class="col-span-8 bg-rose-600 p-2">
          <b-alert id="errormsg" :show="showError" variant="danger">
            Something went wrong. Please try again.
          </b-alert>
        </div>
        <div class="col-span-8 sm:col-span-8">
          <button
            value="submit"
            id="submit"
            class="paybtn text-white font-medium btn block hover:bg-accent w-100 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
          >
            <template v-if="loadingPay">
              <b-spinner small class="mr-2"></b-spinner>
            </template>
            <span v-else> Pay </span>
          </button>
        </div>
        <!-- <div class="col-span-8 sm:col-span-8">
          <button
            @click="goBack"
            id="cancel"
            class="
              text-white
              font-medium
              btn
              block
              hover:bg-accent
              rounded-sm
              w-100
              py-2
              transition-all
              duration-200
              shadow-hard
              hover:shadow-2xl
            "
          >
            Go Back
          </button>
        </div> -->
      </form>
    </div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { OrderCompleted } from "@/services/settings.service";
import FormInput from "@/components/Form/FormInput";

export default {
  name: "PayPalCard",
  components: { FormInput },
  data() {
    return {
      paypal: undefined,
      loadingPay: false,
      loadingCancel: false,
      showError: false,
      cardHolderName: undefined,
      cardHolderNameError: {
        status: null,
        message: "",
      },
      Nameoncard: null,
    };
  },
  props: {
    env: null,
    gateway: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  watch: {
    Nameoncard(val) {
      if (val) {
        this.cardHolderNameError.status = true;
      } else {
        this.cardHolderNameError.status = false;
        this.cardHolderNameError.message = "Please fill in the field";
      }
    },
  },
  async mounted() {
    this.env = process.env.VUE_APP_NAME;
    this.changePayBtnColor();
    // document.getElementById("submit").style.backgroundColor = this.buttonColor;
    // document.getElementById("cancel").style.backgroundColor = this.buttonColor;
    await this.initializePayPal(
      this.gateway.clientId,
      this.gateway.clientToken,
      this.gateway.orderId
    );
  },

  computed: {},
  methods: {
    changePayBtnColor() {
      if (this.env === "give-proudly") {
        document.getElementById("submit").style.backgroundColor = "#00aff2";
      } else {
        document.getElementById("submit").style.backgroundColor = "#b22234";
      }
    },
    goBack() {
      this.$emit("cancel");
    },

    errorFn() {
      var errorList = [];

      if (!this.Nameoncard) {
        this.cardHolderNameError.status = false;
        this.cardHolderNameError.message =
          "Please fill in the Card holder name";
        errorList.push(this.cardHolderNameError.message);
      }
    },

    async initializePayPal(clientId, clientToken, orderId) {
      try {
        this.paypal = await loadScript({
          "client-id": clientId,
          "data-client-token": clientToken,
          components: "buttons,hosted-fields",
        });
        if (this.paypal.HostedFields.isEligible()) {
          const cardFields = await this.paypal.HostedFields.render({
            createOrder: function (/*data, actions*/) {
              return orderId;
            },
            styles: {
              ".valid": {
                color: "green",
              },
              ".invalid": {
                color: "red",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          });
          // then(function (cardFields) {

          document
            .querySelector("#card-form")
            .addEventListener("submit", (event) => {
              event.preventDefault();
              this.errorFn();
              this.loadingPay = true;
              cardFields
                .submit({
                  cardHolderName:
                    document.getElementById("card-holder-name").value,
                })

                .then(async (data /* actions*/) => {
                  console.log("data then ", data);
                  console.log(typeof OrderCompleted);
                  try {
                    const capture = await OrderCompleted(orderId);
                    console.log(capture);
                    if (capture.status == 200) {
                      console.log(capture);
                      window.location.reload();
                    } else {
                      this.loadingPay = false;
                      this.showError = true;
                    }
                  } catch (error) {
                    this.loadingPay = false;
                    this.showError = true;
                  }

                  // this.$emit("payment_done");
                })
                .catch((error) => {
                  this.loadingPay = false;
                  this.showError = true;

                  // TODO Show Error
                  // const msgs = error?.details?.map((d) => d.description);

                  console.log("Submit failed ", error);
                });
            });

          // });
        } else {
          this.showError = true;
          console.log("NO HOSTED FIELDS");
        }
      } catch (error) {
        this.showError = true;
        console.log("PayPal SDK Failed ", error);
      }
    },
  },
};
</script>

<style scoped>
.grid {
  width: 100%;
}

.card_field {
  height: 50px;
}

.paybtn {
  border-radius: 8px;
}
</style>
