<template>
    <div>
        <h6 class="text-left text-dark font-weight-semi-bold font-primary mt-3">
            Permanently Delete All Data
        </h6>
        <!--Reset delete button -->
        <div class="d-flex justify-content-center mt-2">
            <FormButton variant="primary" class="font-weight-normal text-white border-dark" type="submit"
                @click.native="openResetItemModal">Delete All Data</FormButton>
        </div>

        <AccountReset :isShow="isShowResetModal" @onClose="onCloseResetItemModal" :itemContent="resetItem" />
    </div>
</template>

<script>
import FormButton from "@/components/Form/FormButton";
import AccountReset from "@/components/Settings/AccountResetModal.vue";

export default {
    name: "DeleteAccountData",
    components: {
        FormButton,
        AccountReset
    },
    data() {
        return {
            isShowResetModal: false,
            resetItem: null,
        }
    },
    methods: {
        isAuthFresh(freshnesSeconds = 30) {
            const now = Date.now() / 1000
            const auth_time = this.$keycloak.idTokenParsed.auth_time
            return now - auth_time < freshnesSeconds
        },
        openResetItemModal(item, button) {
            if (!this.isAuthFresh(30)) {
                this.$keycloak.login({
                    maxAge: 1,
                })
            } else {
                this.isShowResetModal = true;
                this.resetItem = item;
                this.$root.$emit("bv::show::modal", "reset-account-row-modal", button);
            }
        },
        onCloseResetItemModal() {
            console.log("CLose event receivce")
            this.isShowResetModal = false;
        },
    }
}
</script>