<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 card-row"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container"
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- admin details row -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-items-start justify-content-center"
                >
                  <h6
                    class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
                  >
                    Account details
                  </h6>
                </b-col>
                <b-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-items-end align-items-sm-end justify-content-end"
                >
                  <b-row class="mx-n1">
                    <!-- <b-button
                      size="sm"
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        shadow-none
                        border-secondary
                        edit-btn
                        mx-2
                        my-1
                      "
                      pill
                      variant="white"
                      @click="openEditItemModal($event.target)"
                    >
                      <font-awesome-icon
                        icon="pen"
                        aria-label="Edit User"
                        class="px-0 w-auto edit-icon text-secondary"
                      ></font-awesome-icon>
                    </b-button> -->
                    <b-button
                      size="sm"
                      class="d-flex flex-row align-items-center justify-content-center shadow-none border-primary edit-btn mx-2 my-1"
                      pill
                      variant="white"
                      @click="openPasswordModal($event.target)"
                    >
                      <font-awesome-icon
                        icon="key"
                        aria-label="Delete User"
                        class="px-0 w-auto edit-icon text-primary"
                      ></font-awesome-icon>
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <!-- campaign name -->
                <div class="inner-row-grid py-2 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Campaign Name :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ campaignName }}
                  </span>
                </div>
                <!-- admin fullName -->
                <div class="inner-row-grid py-2 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Treasurer’s Full Name :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ adminName }}
                  </span>
                </div>
                <!-- campaign ID -->
                <!-- <div class="inner-row-grid py-2 w-100">
                <span
                  class="text-left text-prime-gray font-secondary col-align"
                >
                  Campaign ID :
                </span>
                <span class="text-left text-dark font-secondary">
                  {{ campaignID }}
                </span>
              </div> -->
                <!-- campaign Website URL -->
                <div class="inner-row-grid py-2 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Campaign Website URL :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ campaignWebsiteURL }}
                  </span>
                </div>
                <!-- campaign donation page URL -->
                <div class="inner-row-grid py-2 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Donation Page :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    <a target="_blank" :href="campaignDonationPage">{{
                      campaignDonationPage
                    }}</a>
                  </span>
                </div>
                <!-- admin email -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Treasurer’s Email :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ adminEmail }}
                  </span>
                </div>
                <!-- contact Number -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Contact No :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ contactNumber ? contactNumber : "N/A" }}
                  </span>
                </div>
                <!-- admin joined date -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Joined Date :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ createdAt ? createdAt.split("T")[0] : "06/08/2021" }}
                  </span>
                </div>
                <!-- admin type -->
                <!-- <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Type :
                  </span>
                  <template v-if="accountDetails.adminType == 'Administrator'">
                    <span
                      class="
                        text-center
                        font-secondary font-weight-normal
                        text-primary text-small
                        bg-light
                        px-2
                        py-1
                        rounded-lg
                        admin-type-badge
                        mb-5
                      "
                    >
                      Administrator
                    </span>
                  </template>

                  <template v-else>
                    <span
                      class="
                        text-center
                        font-secondary font-weight-normal
                        text-warning text-small
                        bg-light
                        px-2
                        py-1
                        rounded-lg
                        admin-type-badge
                      "
                    >
                    </span>
                  </template>
                </div> -->
              </b-row>

              <!-- <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  cols="6"
                  sm="6"
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                  "
                >
                  <h6
                    class="
                      text-left text-dark
                      font-weight-semi-bold font-primary
                      mb-2
                      mt-3
                    "
                  >
                    Payment Infomation
                  </h6>
                </b-col>
                <b-col
                  cols="6"
                  sm="6"
                  class="
                    d-flex
                    flex-column
                    align-items-end align-items-sm-end
                    justify-content-end
                  "
                >
                </b-col>
              </b-row> -->
              <!-- 
              <div class="inner-row-grid py-2 w-100">
                <span
                  class="
                    text-left text-prime-gray
                    font-secondary
                    col-align
                    mt-lg-3 mt-0
                  "
                >
                  Stripe Account :
                </span>
                <FormButton
                  v-if="!onboardingCompleted"
                  variant="primary"
                  :isLoading="stripeOnboardLoading"
                  isBlock
                  class="font-secondary font-weight-normal text-white mt-2"
                  type="submit"
                  @click.native="setupStripe"
                  >Setup Stripe Account</FormButton
                >
                <FormButton
                  v-else
                  variant="primary"
                  isBlock
                  class="font-secondary font-weight-normal text-white mt-2"
                  type="submit"
                  @click="loginStripe"
                  >Update Stripe Account</FormButton
                >
              </div>

              <div class="inner-row-grid py-2 w-100">
                <span
                  class="
                    text-left text-prime-gray
                    font-secondary
                    col-align
                    mt-lg-3 mt-0
                  "
                >
                  Log in to Stipe :
                </span>
                <FormButton
                  v-if="canLogin"
                  variant="primary"
                  isBlock
                  class="font-secondary font-weight-normal text-white mt-2"
                  type="submit"
                  :isLoading="loginLoading"
                  @click.native="loginStripe"
                  >Login Stripe</FormButton
                >
                <FormButton
                  v-else
                  disabled
                  variant="primary"
                  isBlock
                  class="font-secondary font-weight-normal text-white mt-2"
                  type="submit"
                  :isLoading="loginLoading"
                  @click.native="loginStripe"
                  >Login Stripe</FormButton
                >
              </div>
              <div class="inner-row-grid py-2 w-100">
                <span
                  class="
                    text-left text-prime-gray
                    font-secondary
                    col-align
                    mt-lg-3 mt-0
                  "
                >
                  Subscription:
                </span>
                <FormButton
                  v-if="!setupFeePaid"
                  variant="primary"
                  isBlock
                  class="font-secondary font-weight-normal text-white mt-2"
                  type="submit"
                  :isLoading="setupFeeLoading"
                  @click.native="paySetupFee"
                  >Pay Onboarding Fee</FormButton
                >
                <div v-else>Subscribed</div>
              </div> -->
              <hr class="border-bottom-1 row-divider" />
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-items-start justify-content-center mb-3"
                >
                  <h6
                    class="text-left text-dark font-weight-semi-bold font-primary mt-3"
                  >
                    Minimum contribution value
                  </h6>
                </b-col>
                <b-col cols="12">
                  <FormInput
                    groupId="minVaue-name-group"
                    id="minVaue-name-group-input"
                    class="text-prime-gray"
                    :state="minValueError.status"
                    :invalidFeedback="`${minValueError.message}`"
                    label="Set Minimum Value"
                    v-model="minValue"
                    isRequired
                    trim
                    @input="onKeypressDollorSign()"
                    form="admin-registration-form"
                    type="text"
                    autocomplete="number"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-center w-100"
                no-gutters
              >
                <b-col
                  class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn"
                  cols="6"
                >
                  <FormButton
                    variant="primary"
                    isBlock
                    class="font-weight-normal text-white mt-2 border-dark"
                    type="submit"
                    @click.native="setMinimumValue"
                    :isLoading="isMinValueLoading"
                    :isDisabled="!minValue"
                    >Submit</FormButton
                  >
                </b-col>
              </b-row>
              <hr class="border-bottom-1 row-divider" />
              <!-- Payment gateway options -->
              <component
                :is="paymentAccountOptionsComponent"
                v-bind="paymentAccountProps"
              ></component>

              <hr class="border-bottom-1 row-divider" />
                <!-- <Aristotle  :propsmessage="parentMessage"></Aristotle> -->
                <Aristotle v-if="aristotleData" :details="aristotleData" :forceDisabled="isInfoSubmitted"/>
              <hr class="border-bottom-1 row-divider" />
              <DeleteData></DeleteData>
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-1 py-3 right-column">
              <b-row class="mb-3">
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex flex-column align-items-start justify-content-center"
                >
                  <h6
                    class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
                  >
                    Additional account details
                  </h6>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex flex-column align-items-center align-items-sm-end justify-content-end"
                >
                  <b-row class="mx-n1">
                    <FormButton
                      v-if="canRetain"
                      variant="primary"
                      isBlock
                      class="font-weight-normal text-white mt-2 border-dark"
                      type="submit"
                      @click.native="retainAccount"
                      :isLoading="canRetainLoading"
                      >Retain Account</FormButton
                    >
                  </b-row>
                </b-col>
              </b-row>
              <h6 class="font-primary text-prime-gray mt-4 mt-lg-0">
                Candidate type
              </h6>
              <b-row
                class="d-flex flex-row align-items-center justify-content-center w-100 mt-3"
              >
                <b-form-group class="text-center" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="selectedCandidate"
                    :options="candidateOptions"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options-1"
                    class="custom-checkbox"
                    type="checkbox"
                    :disabled="isInfoSubmitted"
                  ></b-form-radio-group>
                </b-form-group>
              </b-row>
              <hr class="row-divider" />
              <!-- Additional account details form -->
              <form
                id="reset-password-form"
                class="d-flex flex-column align-items-start justify-content-start w-100 mt-4"
              >
                <template v-if="selectedCandidate == 'federalCandidate'">
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="candidateName-name-group"
                        id="candidateName-name-group-input"
                        class="text-prime-gray"
                        :state="candidateNameError.status"
                        :invalidFeedback="`${candidateNameError.message}`"
                        label="Candidate Name"
                        v-model="candidateName"
                        isRequired
                        :isDisabled="isInfoSubmitted"
                        trim
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>

                  <h6 class="font-primary text-prime-gray mt-4 mt-lg-0">
                    Party
                  </h6>
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectedParty"
                        :options="partyOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-2"
                        :disabled="isInfoSubmitted"
                        class="custom-checkbox text-prime-gray"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-row>

                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                    v-if="selectedParty == 'other'"
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="specifyPatry-name-group"
                        id="specifyPatry-name-group-input"
                        class="text-prime-gray"
                        :state="specifyPatryError.status"
                        :invalidFeedback="`${specifyPatryError.message}`"
                        label="Specify Party"
                        v-model="specifyPatry"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>

                  <h6 class="font-primary mt-4 mt-lg-0 text-prime-gray">
                    Race information
                  </h6>
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectedRace"
                        :options="raceInfoOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-3"
                        :disabled="isInfoSubmitted"
                        class="custom-checkbox text-prime-gray"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-row>

                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    v-if="selectedRace == 'house' || selectedRace == 'senate'"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="state-name-group"
                        id="state-name-group-input"
                        class="text-prime-gray"
                        :state="stateError.status"
                        :invalidFeedback="`${stateError.message}`"
                        label="State"
                        v-model="state"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>

                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                    v-if="selectedRace == 'house'"
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="districtNo-name-group"
                        id="districtNo-name-group-input"
                        class="text-prime-gray"
                        :state="districtNoError.status"
                        :invalidFeedback="`${districtNoError.message}`"
                        label="District No"
                        v-model="districtNo"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>

                  <h6 class="font-primary mt-4 mt-lg-0 text-prime-gray">
                    Election type
                  </h6>
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectElection"
                        :options="electionOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-4"
                        :disabled="isInfoSubmitted"
                        class="custom-checkbox text-prime-gray"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-row>
                </template>

                <template v-if="selectedCandidate == 'federalPAC'">
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="pacName-name-group"
                        id="pacName-name-group-input"
                        class="text-prime-gray"
                        :state="pacNameError.status"
                        :invalidFeedback="`${pacNameError.message}`"
                        label="PAC Name"
                        v-model="pacName"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="selectedCandidate == 'candidateBar'">
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="candidateName2-name-group"
                        id="candidateName2-name-group-input"
                        class="text-prime-gray"
                        :state="candidateNameError.status"
                        :invalidFeedback="`${candidateNameError.message}`"
                        label="Candidate Name"
                        v-model="candidateName"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>

                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="nameOfRace-name-group"
                        id="nameOfRace-name-group-input"
                        class="text-prime-gray"
                        :state="nameOfRaceError.status"
                        :invalidFeedback="`${nameOfRaceError.message}`"
                        label="Name of  The Race"
                        v-model="nameOfRace"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>
                  <h6 class="font-primary text-prime-gray mt-4 mt-lg-0">
                    Party
                  </h6>
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectedParty"
                        :options="partyOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-2"
                        :disabled="isInfoSubmitted"
                        class="custom-checkbox text-prime-gray"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-row>
                  <h6 class="font-primary mt-4 mt-lg-0 text-prime-gray">
                    Election type
                  </h6>
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectElection"
                        :options="electionOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options-4"
                        :disabled="isInfoSubmitted"
                        class="custom-checkbox text-prime-gray"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-row>
                </template>
                <template v-if="selectedCandidate == 'statePAC'">
                  <b-row
                    class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <b-col cols="12">
                      <FormInput
                        groupId="pacName-name-group"
                        id="pacName-name-group-input"
                        class="text-prime-gray"
                        :state="pacNameError.status"
                        :invalidFeedback="`${pacNameError.message}`"
                        label="PAC Name"
                        v-model="pacName"
                        isRequired
                        trim
                        :isDisabled="isInfoSubmitted"
                        form="admin-registration-form"
                        type="text"
                      ></FormInput>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mb-3"
                  no-gutters
                >
                  <b-col cols="12">
                    <b-form-datepicker
                      class="date-picker font-primary datepicker align-items-center"
                      v-model="dateOfElection"
                      no-flip
                      :min="new Date().toISOString().substr(0, 10)"
                      :max="max"
                      :state="dateOfElectionError.status"
                      :invalidFeedback="`${dateOfElectionError.message}`"
                      placeholder="Date of Election"
                      :disabled="isInfoSubmitted"
                      locale="en-US"
                      button-variant="primary"
                      nav-button-variant="primary"
                      today-variant="primary"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </b-col>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <b-col
                    cols="12"
                    v-if="
                      selectedCandidate != 'federalCandidate' &&
                      selectedCandidate != 'federalPAC'
                    "
                  >
                    <FormInput
                      groupId="identificationNo-name-group"
                      id="identificationNo-name-group-input"
                      class="text-muted-gray"
                      :state="identificationNoError.status"
                      :invalidFeedback="`${identificationNoError.message}`"
                      label="State Identification No."
                      v-model="identificationNo"
                      trim
                      form="admin-registration-form"
                      type="text"
                      :isDisabled="isInfoSubmitted"
                      @input="onKeypressIdentification()"
                    ></FormInput>
                  </b-col>
                  <b-col
                    cols="12"
                    v-if="
                      selectedCandidate == 'federalPAC' ||
                      selectedCandidate == 'federalCandidate'
                    "
                  >
                    <FormInput
                      groupId="identificationNo-name-group"
                      id="identificationNo-name-group-input"
                      class="text-muted-gray"
                      :state="identificationNoError.status"
                      :invalidFeedback="`${identificationNoError.message}`"
                      label="FEC Identification No."
                      v-model="identificationNo"
                      isRequired
                      trim
                      form="admin-registration-form"
                      type="text"
                      :isDisabled="isInfoSubmitted"
                      @input="onKeypressIdentification()"
                    ></FormInput>
                  </b-col>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="taxId-name-group"
                      id="taxId-name-group-input"
                      class="text-prime-gray"
                      :state="taxIdError.status"
                      :invalidFeedback="`${taxIdError.message}`"
                      label="Committee Tax ID No."
                      v-model="taxId"
                      isRequired
                      trim
                      :isDisabled="isInfoSubmitted"
                      form="admin-registration-form"
                      type="text"
                      @input="onKeypressTaxId()"
                    ></FormInput>
                  </b-col>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="mailingAdress-name-group"
                      id="mailingAdress-name-group-input"
                      class="text-prime-gray"
                      :state="mailingAdressError.status"
                      :invalidFeedback="`${mailingAdressError.message}`"
                      label="Campaign Physical Address"
                      v-model="mailingAdress"
                      isRequired
                      trim
                      :isDisabled="isInfoSubmitted"
                      form="admin-registration-form"
                      type="text"
                    ></FormInput>
                  </b-col>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="contactNo-name-group"
                      id="contactNo-name-group-input"
                      class="text-prime-gray"
                      :state="contactNoError.status"
                      :invalidFeedback="`${contactNoError.message}`"
                      label="Treasurer’s Phone No."
                      v-model="contactNo"
                      isRequired
                      trim
                      :isDisabled="isInfoSubmitted"
                      form="admin-registration-form"
                      type="text"
                    ></FormInput>
                  </b-col>
                </b-row>

                <hr class="border-bottom-1 row-divider" />

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 px-3"
                  no-gutters
                >
                  <b-form-checkbox
                    v-model="statementDeclare"
                    name="checkbox-1"
                    class="text-center small"
                    :disabled="isInfoSubmitted ? true : false"
                  >
                    I hereby declare that the information provided above is true
                    and correct.
                  </b-form-checkbox>
                </b-row>

                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                  no-gutters
                >
                  <div
                    v-if="errorStatementDeclare.status == false"
                    class="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {{ errorStatementDeclare.message }}
                    <button
                      @click="errorStatementDeclare.status = null"
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 px-3 mt-4"
                  no-gutters
                >
                  <b-form-checkbox
                    v-model="acceptTermAndConditon"
                    name="checkbox-2"
                    class="text-center small"
                    :disabled="isInfoSubmitted ? true : false"
                  >
                    I accept the
                    <a target="_blank" @click.stop :href="TermsPage"
                      >terms & conditions</a
                    >
                    &
                    <a target="_blank" @click.stop :href="PrivacyPage"
                      >privacy policy</a
                    >
                    of this platform.
                  </b-form-checkbox>
                </b-row>

                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                  no-gutters
                >
                  <div
                    v-if="errorAcceptTermAndConditon.status == false"
                    class="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {{ errorAcceptTermAndConditon.message }}
                    <button
                      @click="errorAcceptTermAndConditon.status = null"
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </b-row>

                <b-row
                  class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-5"
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInput
                      groupId="declarationName-name-group"
                      id="declarationName-name-group-input"
                      class="text-prime-gray"
                      :state="declarationNameError.status"
                      :invalidFeedback="`${declarationNameError.message}`"
                      label="Declaration Name"
                      v-model="declarationName"
                      isRequired
                      trim
                      :isDisabled="isInfoSubmitted"
                      form="admin-registration-form"
                      type="text"
                    ></FormInput>
                  </b-col>
                </b-row>

                <!-- form action -->
                <b-row
                  class="d-flex flex-row align-items-center justify-content-center w-100"
                  no-gutters
                >
                  <b-col
                    class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn"
                    cols="6"
                  >
                    <FormButton
                      variant="primary"
                      isBlock
                      class="font-weight-normal text-white mt-2 border-dark"
                      type="submit"
                      @click.native="AdditionalInfoCreate"
                      :isLoading="isLoading"
                      :isDisabled="!canUpdateInfo || !disableButtons"
                      >Submit</FormButton
                    >
                  </b-col>
                </b-row>
                <b-row
                  class="d-flex flex-row align-items-center justify-content-center w-100"
                  no-gutters
                >
                  <!-- <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      px-0
                      py-2
                    "
                    cols="9"
                    sm="6"
                  >
                    <FormButton
                      variant="primary"
                      isBlock
                      class="
                        font-secondary font-weight-normal
                        text-white
                        mt-2
                        submitBtn
                      "
                      type="submit"
                      :isLoading="setupFeeLoading"
                      @click.native="paySetupFee"
                      :isDisabled="
                        this.setupFeePaid ||
                        !disableButtons ||
                        !this.isInfoSubmitted
                      "
                      >{{
                        this.setupFeePaid
                          ? "Subscribed"
                          : "Proceed with Payment"
                      }}</FormButton
                    >
                  </b-col> -->
                  <component
                    :is="gatewayComponent"
                    v-bind="setupFeeProps"
                  ></component>
                </b-row>
              </form>
              <!-- PayPal Logo -->
              <b-row>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                >
                  <tr>
                    <td align="center"></td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a
                        href="https://www.paypal.com/webapps/mpp/paypal-popup"
                        title="How PayPal Works"
                        onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"
                      >
                        <img
                          src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg"
                          border="0"
                          alt="PayPal Acceptance Mark"
                          style="width: 260px"
                        />
                      </a>
                    </td>
                  </tr>
                </table>
              </b-row>
              <!-- PayPal Logo -->
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center px-3 pt-2 pt-sm-0 pb-1 pb-sm-2"
            no-gutters
          >
            <b-col
              cols="12"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <!-- <b-img
                class="footer-img mt-2"
                src="@/assets/logo_footer_black_vault.png"
                contain
              ></b-img> -->
              <h5 class="version-number mb-0 text-muted text-center w-100">
                {{ this.version }}
              </h5>
            </b-col>
          </b-row>
          <!-- overlay loader -->
          <b-overlay :show="isLoading" class="mx-n3" no-wrap></b-overlay>
        </b-card>
        <!-- <DeleteAdmin
          :isShow="isShowDeleteAdminModal"
          @onClose="onCloseDeleteItemModal"
          :itemContent="deleteItem"
          :key="showDeleteAdminModalKey"
        /> -->
        <EditAdmin
          :isShow="isShowEditAdminModal"
          @onClose="onCloseEditItemModal"
          :itemContent="editItem"
        />
        <PasswordSettings
          :isShow="isShowPassowrdModal"
          @onClose="onClosePasswordModal"
          :itemContent="passwordItem"
          @reloadFn="callInitFn"
          :key="showPwModalKey"
        />
      </b-row>
    </b-container>

    <!-- <FormButton
      variant="primary"
      class="font-secondary font-weight-normal text-white mt-2"
       ="setupStripe"
      >Setup Stripe</FormButton
    > -->
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
// import FormInputGroup from "@/components/Form/FormInputGroup";
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";

// import DeleteAdmin from "@/components/Settings/AccountDeleteModal";
import EditAdmin from "@/components/Settings/AccountEditModal";
import PasswordSettings from "@/components/Settings/PasswordSettings";
import PayWithPayPal from "@/components/Settings/Payments/PayWithPayPal";
import PayPalAccount from "@/components/Settings/Payments/PayPalAccount";
import Aristotle from "@/components/Settings/Aristotle";
import ConnectionStatus from "@/components/Settings/ConnectionStatus";
import DeleteData from "@/components/Settings/DeleteData/DeleteData.vue";

import Vue from "vue";

// services
import {
  GetDetails,
  RetainAccount,
  PaySetupFee,
  CreateAdditionalInfo,
  GetAdditionalInfo,
} from "../services/settings.service";
import { CreateLayout } from "../services/campaignTemplate.service";

export default {
  name: "Settings",
  components: {
    HomeLayout,
    // FormInputGroup,
    FormButton,
    FormInput,
    // DeleteAdmin,
    EditAdmin,
    PasswordSettings,
    PayWithPayPal,
    PayPalAccount,
    DeleteData,
    Aristotle,
    ConnectionStatus,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 12);
    // minDate.setDate(31)
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 12);
    // maxDate.setDate(31)
    return {
      accountDetails: {
        firstName: "john",
        email: "john@example.com",
        adminType: "Administrator",
      },
      campaignID: null,
      contactNumber: null,
      adminId: null,
      isReset: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
      apiLoading: false,
      campaignWebsiteURL: null,
      campaignName: null,
      createdAt: null,
      isLoading: false,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
      totalRows: 500,
      isShowDeleteAdminModal: false,
      isShowResetModal: false,
      isShowEditAdminModal: false,
      isShowPassowrdModal: false,
      showDeleteAdminModalKey: Number(new Date()) + 110,
      deleteItem: null,
      resetItem: null,
      editItem: null,
      passwordItem: null,
      adminName: null,
      adminEmail: null,
      campaignDonationPage: null,
      TermsPage: null,
      PrivacyPage: null,
      // Payments
      canLogin: undefined,
      setupFeePaid: undefined,
      onboardingCompleted: undefined,
      canUpdateStripe: undefined,
      canRetain: false,
      canRetainLoading: false,
      showPwModalKey: Number(new Date()) + 100,
      loginLoading: undefined,
      stripeOnboardLoading: undefined,
      setupFeeLoading: undefined,
      minValueError: {
        status: null,
        message: "",
      },
      minValue: null,
      selectedCandidate: "federalCandidate",
      candidateOptions: [
        { text: "Federal Candidate", value: "federalCandidate" },
        { text: "Federal PAC", value: "federalPAC" },
        { text: "State/Local Candidate bar", value: "candidateBar" },
        { text: "State PAC/501(c)4", value: "statePAC" },
      ],
      candidateName: null,
      candidateNameError: {
        status: null,
        message: "",
      },
      selectedParty: "republican",
      partyOptions: [
        { text: "Republican", value: "republican" },
        { text: "Democrat", value: "democrat" },
        { text: "Other", value: "other" },
      ],
      //  partyOptionsState: [
      //   { text: "Republican", value: "republican" },
      //   { text: "Democrat", value: "democrat" },
      // ],
      specifyPatry: null,
      specifyPatryError: {
        status: null,
        message: "",
      },
      selectedRace: "house",
      raceInfoOptions: [
        { text: "House", value: "house" },
        { text: "Senate", value: "senate" },
        { text: "Presidential", value: "presidential" },
      ],
      state: null,
      stateError: {
        status: null,
        message: "",
      },
      districtNo: null,
      districtNoError: {
        status: null,
        message: "",
      },
      selectElection: "primary",
      electionOptions: [
        { text: "Primary", value: "primary" },
        { text: "General", value: "general" },
      ],
      dateOfElection: null,
      dateOfElectionError: {
        status: null,
        message: "",
      },
      identificationNo: null,
      identificationNoError: {
        status: null,
        message: "",
      },
      taxId: null,
      taxIdError: {
        status: null,
        message: "",
      },
      mailingAdress: null,
      mailingAdressError: {
        status: null,
        message: "",
      },
      contactNo: null,
      contactNoError: {
        status: null,
        message: "",
      },
      statementDeclare: false,
      acceptTermAndConditon: false,
      declarationName: null,
      declarationNameError: {
        status: null,
        message: "",
      },
      pacName: null,
      pacNameError: {
        status: null,
        message: "",
      },
      nameOfRace: null,
      nameOfRaceError: {
        status: null,
        message: "",
      },
      errorAcceptTermAndConditon: {
        status: null,
        message: "",
      },
      errorStatementDeclare: {
        status: null,
        message: "",
      },
      isMinValueLoading: false,
      isInfoSubmitted: false,
      canUpdateInfo: false,
      disableButtons: false,
      min: minDate,
      max: maxDate,
      setupFeeProps: undefined,
      gatewayComponent: undefined,
      gatewayComponentKeys: {
        PAYPAL: "PayWithPayPal",
        // PAYPAL: "PayWithPayPalAdvanced",
      },
      paymentAccountComponentKeys: {
        PAYPAL: "PayPalAccount",
      },
      paymentAccountOptionsComponent: undefined,
      paymentAccountProps: undefined,
      aristotleData: undefined,
    };
  },

  mounted() {
    console.log("mounted");
    this.adminName = Vue.$keycloak.idTokenParsed.name;
    this.adminEmail = Vue.$keycloak.idTokenParsed.email;
    //this.firstName = Vue.GetDetails.name;
    // this.joinedDate = Vue.$keycloak.idTokenParsed.
    // this.type = Vue.$keycloak.idTokenParsed.

    this.getSettingDetails();
    // this.getDetails();
    this.initFn();
  },
  watch: {
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmNewPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmNewPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmNewPasswordError.status = true;
            } else {
              this.confirmNewPasswordError.status = false;
              this.confirmNewPasswordError.message = "Passwords do not match";
            }
          }
        } else {
          this.confirmNewPasswordError.status = false;
          this.confirmNewPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
    candidateName(val) {
      if (val) {
        this.candidateNameError.status = true;
      } else {
        this.candidateNameError.status = false;
        this.candidateNameError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.candidateNameError.status = null;
      }
    },
    specifyPatry(val) {
      if (val) {
        this.specifyPatryError.status = true;
      } else {
        this.specifyPatryError.status = false;
        this.specifyPatryError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.specifyPatryError.status = null;
      }
    },
    state(val) {
      if (val) {
        this.stateError.status = true;
      } else {
        this.stateError.status = false;
        this.stateError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.stateError.status = null;
      }
    },
    districtNo(val) {
      if (val) {
        this.districtNoError.status = true;
      } else {
        this.districtNoError.status = false;
        this.districtNoError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.districtNoError.status = null;
      }
    },
    dateOfElection(val) {
      if (val) {
        this.dateOfElectionError.status = true;
      } else {
        this.dateOfElectionError.status = false;
        this.dateOfElectionError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.dateOfElectionError.status = null;
      }
    },
    // identificationNo(val) {
    //   if (val) {
    //     this.identificationNoError.status = true;
    //   } else {
    //     this.identificationNoError.status = false;
    //     this.identificationNoError.message = "Please fill in the field";
    //   }
    //   if (this.isInfoSubmitted) {
    //     this.identificationNoError.status = null;
    //   }
    // },
    // if (val.length < 8) {
    //       this.identificationNoError.status = false;
    //       this.identificationNoError.message = "Identification No must have at least 8 characters";
    //     } else if (val.length > 8) {
    //       this.identificationNoError.status = false;
    //       this.identificationNoError.message = "Identification No must not exceed 8 characters";
    //     }

    taxId(val) {
      // if (val) {
      //   this.taxIdError.status = true;
      // } else {
      //   this.taxIdError.status = false;
      //   this.taxIdError.message = "Please fill in the field";
      // }
      // if (this.isInfoSubmitted) {
      //   this.taxIdError.status = null;
      // }

      // val = val.split(" ").join("-");
      if (val != null) {
        this.taxIdError.status = true;
        if (!val) {
          this.taxIdError.status = false;
          this.taxIdError.message = "Please fill in the field";
        } else if (val.length < 10) {
          this.taxIdError.status = false;
          this.taxIdError.message = "Tax ID must have at least 9 characters";
        } else if (val.length > 10) {
          this.taxIdError.status = false;
          this.taxIdError.message = "Tax ID must not exceed 9 characters";
        }
      }
      if (this.isInfoSubmitted) {
        this.taxIdError.status = null;
      }
      val = val.split(" ").join("-");
    },
    mailingAdress(val) {
      if (val) {
        this.mailingAdressError.status = true;
      } else {
        this.mailingAdressError.status = false;
        this.mailingAdressError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.mailingAdressError.status = null;
      }
    },
    contactNo(val) {
      if (val) {
        this.contactNoError.status = true;
      } else {
        this.contactNoError.status = false;
        this.contactNoError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.contactNoError.status = null;
      }
    },
    declarationName(val) {
      if (val) {
        this.declarationNameError.status = true;
      } else {
        this.declarationNameError.status = false;
        this.declarationNameError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.declarationNameError.status = null;
      }
    },
    pacName(val) {
      if (val) {
        this.pacNameError.status = true;
      } else {
        this.pacNameError.status = false;
        this.pacNameError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.pacNameError.status = null;
      }
    },
    nameOfRace(val) {
      if (val) {
        this.nameOfRaceError.status = true;
      } else {
        this.nameOfRaceError.status = false;
        this.nameOfRaceError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.nameOfRaceError.status = null;
      }
    },

    minValue(val) {
      var newVal = val.slice(1);
      if (newVal) {
        this.minValueError.status = true;
      } else {
        this.minValueError.status = false;
        this.minValueError.message = "Please fill in the field";
      }
    },

    statementDeclare() {
      this.errorStatementDeclare.message = null;
      this.errorStatementDeclare.status = true;
    },
    acceptTermAndConditon() {
      this.errorAcceptTermAndConditon.message = null;
      this.errorAcceptTermAndConditon.status = true;
    },
  },
  methods: {
    // TODO Check why response ends up in error block

    retainAccount() {
      this.canRetainLoading = true;

      RetainAccount()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          console.log("ERROR S02");
        })
        .finally(() => {
          this.canRetainLoading = false;
        });
    },

    paySetupFee() {
      this.setupFeeLoading = true;
      PaySetupFee()
        .then((res) => {
          window.location.replace(res.data.content.redirect);
        })
        .catch((er) => {
          window.location.replace(er.data.content.redirect);
        })
        .finally(() => {
          this.setupFeeLoading = true;
        });
    },
    // async getDetails() {
    //   try {
    //     this.isLoading = true;
    //     let { data } = await GetDetails();

    //     const details = data.content.campaign;

    //     this.campaignWebsiteURL = details.url ? details.url : "N/A";
    //     this.campaignName = details.name ? details.name : "N/A";
    //     // this.contactNumber = details.ownerContact
    //     //   ? details.ownerContact
    //     //   : "N/A";
    //     this.createdAt = details.created_at ? details.created_at : "N/A";
    //     this.campaignID = details.idName ? details.idName : "N/A";
    //   } catch (error) {
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },
    async getSettingDetails() {
      try {
        this.isLoading = true;
        const res = await GetDetails();

        console.log("ARIs",res)
        // stripe data
        const data = res.data.content.gateway;
        this.aristotleData = res.data.content.aristotle;

        this.setupFeeProps = {
          setupFeePaid: res.data.content.setupFeePaid,
          forceDisable: res.data.content.canUpdateInfo,
        };

        this.paymentAccountProps = {
          gatewayOptions: data,
          campaign: res.data.content.campaign,
          forceDisable: !res.data.content.setupFeePaid,
        };

        // Set Payment gateway options component key
        this.paymentAccountOptionsComponent =
          this.paymentAccountComponentKeys[
            res.data.content.prefferedPaymentGateway
          ];

        // Set setup fee component key
        this.gatewayComponent =
          this.gatewayComponentKeys[res.data.content.prefferedPaymentGateway];

        this.disableButtons = true;
        if (res.data.content.minimumDonation)
          this.minValue = "$ " + res.data.content.minimumDonation;
        this.campaignDonationPage = `${process.env.VUE_APP_WEB_URL}/${res.data.content.campaign.idName}`;
        this.TermsPage = `${process.env.VUE_APP_WEB_URL}/terms`;
        this.PrivacyPage = `${process.env.VUE_APP_WEB_URL}/privacy`;

        // campaign data
        const campaign = res.data.content.campaign;
        this.campaignWebsiteURL = campaign.url ? campaign.url : "N/A";
        this.campaignName = campaign.name ? campaign.name : "N/A";
        this.createdAt = campaign.created_at ? campaign.created_at : "N/A";
        this.campaignID = campaign.idName ? campaign.idName : "N/A";

        this.canUpdateInfo = res.data.content.canUpdateInfo;
      } catch (error) {
        console.log(error);
        console.log("ERROR S04", error);
      } finally {
        this.isLoading = false;
      }
    },
    async resetPwdFn() {},
    errorFn() {
      // if (!this.oldPassword) {
      //   this.oldPasswordError.status = false;
      //   this.oldPasswordError.message = "Please fill in your old password";
      // }
      // if (!this.newPassword) {
      //   this.newPasswordError.status = false;
      //   this.newPasswordError.message = "Please fill in your new password";
      // } else if (this.newPassword < 6) {
      //   this.newPasswordError.status = false;
      //   this.newPasswordError.message =
      //     "A minimum length of 6 characters is required";
      // }
      // if (!this.confirmNewPassword) {
      //   this.confirmNewPasswordError.status = false;
      //   this.confirmNewPasswordError.message =
      //     "Please fill in your new password again";
      // } else if (this.confirmNewPassword != this.newPassword) {
      //   this.confirmNewPasswordError.status = false;
      //   this.confirmNewPasswordError.message = "Passwords do not match";
      // }

      var errorList = [];

      if (this.selectedCandidate == "federalCandidate") {
        if (!this.candidateName) {
          this.candidateNameError.status = false;
          this.candidateNameError.message = "Please fill in candidate name";
          errorList.push(this.candidateNameError.message);
        }
        if (this.selectedParty == "other") {
          if (!this.specifyPatry) {
            this.specifyPatryError.status = false;
            this.specifyPatryError.message = "Please fill in specify party";
            errorList.push(this.specifyPatryError.message);
          }
        }
        if (this.selectedRace == "house") {
          if (!this.state) {
            this.stateError.status = false;
            this.stateError.message = "Please fill in state";
            errorList.push(this.stateError.message);
          }
        }
        if (this.selectedRace == "house")
          if (!this.districtNo) {
            this.districtNoError.status = false;
            this.districtNoError.message = "Please fill in district no";
            errorList.push(this.districtNoError.message);
          }

        // if (!this.identificationNo) {
        //   this.identificationNoError.status = false;
        //   this.identificationNoError.message =
        //     "Please fill in identification no";
        //   errorList.push(this.identificationNoError.message);
        // }
      }
      if (this.selectedCandidate == "federalPAC") {
        if (!this.pacName) {
          this.pacNameError.status = false;
          this.pacNameError.message = "Please fill in PAC name";
          errorList.push(this.pacNameError.message);
        }
      }
      if (this.selectedCandidate == "candidateBar") {
        if (!this.candidateName) {
          this.candidateNameError.status = false;
          this.candidateNameError.message = "Please fill in candidate name";
          errorList.push(this.candidateNameError.message);
        }
        if (!this.nameOfRace) {
          this.nameOfRaceError.status = false;
          this.nameOfRaceError.message = "Please fill in name of the race";
          errorList.push(this.nameOfRaceError.message);
        }
      }
      if (this.selectedCandidate == "statePAC") {
        if (!this.pacName) {
          this.pacNameError.status = false;
          this.pacNameError.message = "Please fill in PAC name";
          errorList.push(this.pacNameError.message);
        }
        // if (!this.identificationNo) {
        //   this.identificationNoError.status = false;
        //   this.identificationNoError.message =
        //     "Please fill in identification no";
        //   errorList.push(this.identificationNoError.message);
        // }
      }
      if (!this.dateOfElection) {
        this.dateOfElectionError.status = false;
        this.dateOfElectionError.message = "Please fill in date of election";
        errorList.push(this.dateOfElectionError.message);
      }
      //if (!this.identificationNo) {
      // this.identificationNoError.status = false;
      //  this.identificationNoError.message = "Please fill in identification no";
      //  errorList.push(this.identificationNoError.message);
      //}
      if (!this.taxId) {
        this.taxIdError.status = false;
        this.taxIdError.message = "Please fill in tax id";
        errorList.push(this.taxIdError.message);
      } else if (this.taxId.length < 10) {
        this.taxIdError.status = false;
        this.taxIdError.message = "Tax ID must have at least 9 characters";
        errorList.push(this.taxIdError.message);
      } else if (this.taxId.length > 10) {
        this.taxIdError.status = false;
        this.taxIdError.message = "Tax ID must not exceed 9 characters";
        errorList.push(this.taxIdError.message);
      }
      if (!this.mailingAdress) {
        this.mailingAdressError.status = false;
        this.mailingAdressError.message = "Please fill in mainling address";
        errorList.push(this.mailingAdressError.message);
      }
      if (!this.contactNo) {
        this.contactNoError.status = false;
        this.contactNoError.message = "Please fill in contact no";
        errorList.push(this.contactNoError.message);
      }
      if (!this.declarationName) {
        this.declarationNameError.status = false;
        this.declarationNameError.message = "Please fill in declaration name";
        errorList.push(this.declarationNameError.message);
      }
      if (!this.statementDeclare) {
        this.errorStatementDeclare.status = false;
        errorList.push(
          (this.errorStatementDeclare.message =
            "Please tick Terms & Conditions & Privacy policy")
        );
      }

      if (!this.acceptTermAndConditon) {
        this.errorAcceptTermAndConditon.status = false;
        errorList.push(
          (this.errorAcceptTermAndConditon.message =
            "Please tick Declare Information")
        );
      }

      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
    },
    openDeleteItemModal(item, button) {
      this.isShowDeleteAdminModal = true;
      this.deleteItem = item;
      this.$root.$emit("bv::show::modal", "delete-account-row-modal", button);
    },
    openEditItemModal(item, button) {
      this.isShowEditAdminModal = true;
      this.editItem = item;
      this.$root.$emit("bv::show::modal", "edit-account-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteAdminModal = false;
    },
    onCloseEditItemModal() {
      this.isShowEditAdminModal = false;
    },
    openPasswordModal(item, button) {
      this.isShowPassowrdModal = true;
      this.passwordItem = item;
      this.$root.$emit("bv::show::modal", "password-row-modal", button);
    },
    onClosePasswordModal() {
      this.isShowPassowrdModal = false;
    },
    async AdditionalInfoCreate() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          candidateType: this.selectedCandidate,
          candidateName: this.candidateName,
          partyType: this.selectedParty,
          specifyParty: this.specifyPatry,
          raceType: this.selectedRace,
          state: this.state,
          districtNo: this.districtNo,
          electiontype: this.selectElection,
          dateOfElection: this.dateOfElection,
          fecIdentificationNumber: this.identificationNo,
          committeeTaxId: this.taxId,
          campaignMailingAddress: this.mailingAdress,
          treasurerPhoneNumber: this.contactNo,
          declarationName: this.declarationName,
          PACName: this.pacName,
          race: this.nameOfRace,
          acceptTermAndConditon: this.acceptTermAndConditon,
          statementDeclare: this.statementDeclare,
        };

        this.isLoading = true;
        try {
          let { data } = await CreateAdditionalInfo(payload);
          if (data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Additional Account Infomation",
              content: `Account Details has been successfully saved`,
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.isInfoSubmitted = true;
            this.canUpdateInfo = false;
            this.initFn();
            this.candidateNameError.status = null;
            this.specifyPatryError.status = null;
            this.stateError.status = null;
            this.districtNoError.status = null;
            this.dateOfElectionError.status = null;
            this.identificationNoError.status = null;
            this.taxIdError.status = null;
            this.mailingAdressError.status = null;
            this.contactNoError.status = null;
            this.declarationNameError.status = null;
            this.pacNameError.status = null;
            this.nameOfRaceError.status = null;

            setTimeout(() => this.$emit("reloadFn"), 600);
          } else {
            // display email error message in the form field
            let payloadNotify = {
              isToast: true,
              title: "Additional Account Infomation",
              content: "Account Details cannot be saved",
              list: [data.details],
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "Additional Account Infomation",
            content: "Account Details cannot be saved",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isLoading = false;
          this.isInfoSubmitted = true;

          this.setupFeeProps = {
            ...this.setupFeeProps,
            forceDisable: !this.isInfoSubmitted,
          };
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Additional Account Infomation",
          content: "Account Details cannot be saved ",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAdditionalInfo();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.contactNumber = details
            ? data.content.treasurerPhoneNumber
            : "N/A";

          this.isInfoSubmitted = !details.can_update;
          this.canRetain = details.can_retain;

          (this.selectedCandidate = details
            ? details.candidateType
            : "federalCandidate"),
            (this.candidateName = details ? details.candidateName : null),
            (this.selectedParty = details ? details.partyType : "republican"),
            (this.specifyPatry = details ? details.specifyParty : null),
            (this.selectedRace = details ? details.raceType : "house"),
            (this.state = details ? details.state : null),
            (this.districtNo = details ? details.districtNo : null),
            (this.selectElection = details ? details.electiontype : "primary"),
            (this.dateOfElection = details ? details.dateOfElection : null),
            (this.identificationNo = details
              ? details.fecIdentificationNumber
              : null),
            (this.taxId = details ? details.committeeTaxId : null),
            (this.mailingAdress = details
              ? details.campaignMailingAddress
              : null),
            (this.contactNo = details ? details.treasurerPhoneNumber : null),
            (this.declarationName = details ? details.declarationName : null),
            (this.pacName = details ? details.PACName : null),
            (this.nameOfRace = details ? details.race : null),
            (this.acceptTermAndConditon = details
              ? details.acceptTermAndConditon
              : null),
            (this.statementDeclare = details ? details.statementDeclare : null);
        } else {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async setMinimumValue() {
      try {
        this.isMinValueLoading = true;
        let payload = {
          minimumDonation: this.minValue.slice(1).trim(),
        };

        let { data } = await CreateLayout(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Contribution Value",
            content: "Contribution value changed successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Contribution Value",
            content: `Unable to save Contribution value! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Contribution Value",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isMinValueLoading = false;
      }
    },
    onKeypressTaxId() {
      var str = this.taxId;
      str = str.replace("-", "");
      if (str.length > 2) {
        str = str.substring(0, 2) + "-" + str.substring(2);
      }
      this.taxId = str;
    },
    onKeypressIdentification() {
      // var str = this.identificationNo;
      // str = str.replace("C", "");
      // if (str.length > 0) {
      //   str = str.substring(0, 0) + str.substring(0);
      // }
      // this.identificationNo = str;
    },
    async callInitFn() {
      this.showPwModalKey = Number(new Date()) + 100;
    },
    onKeypressDollorSign() {
      var str = this.minValue;
      str = str.replace("$ ", "");
      if (str.length > 0) {
        str = str.substring(0, 0) + "$ " + str.substring(0);
      }
      this.minValue = str;
    },
  },
};
</script>

<style scoped>
/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-gap: 6%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
.footer-img {
  max-width: 150px;
  display: block;
  /* margin-left: 200px;
  margin-right: auto; */
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
  display: block;
  /* margin-left: 250px;
  margin-right: auto; */
}
.edit-btn {
  border: 1px solid;
  width: 35px;
  height: 35px;
  padding: 7px;
}
.col-align {
  width: 110%;
}
.row-divider {
  width: 100%;
  border-top: 1px solid var(--light);
}
.custom-checkbox >>> .custom-control-label::before {
  border-color: var(--light-gray);
}
.form-group >>> select.custom-select {
  border-color: var(--primary);
}
.form-group >>> label {
  color: var(--prime-gray);
  margin-bottom: 10px;
}
.form-group >>> .custom-select {
  color: var(--primary);
}
.custom-checkbox
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.custom-checkbox
  >>> .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--white);
  border-color: var(--light-gray);
}
.submitBtn.form-button {
  border-radius: 4px;
  width: 300px;
}
.form-group >>> :checked ~ label {
  color: var(--primary);
}
/* custom CSS */
.datepicker {
  background-color: transparent;
  z-index: 5;
  border-radius: 4px;
  min-height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  background-color: var(--white);
}
.datepicker >>> label.form-control {
  min-height: 50px;
  box-shadow: none;
  /* calculate the line height by reducing the excess padding */
  line-height: calc(50px - calc(0.375rem * 2));
  color: var(--prime-gray);
}
.datepicker >>> .form-control {
  box-shadow: none;
  /* color: var(--prime-gray) !important; */
}
.date-picker >>> .btn .b-icon.bi {
  color: var(--prime-gray);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: 0 !important;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: 0 !important;
}
.contact-number-input.form-group >>> input.form-control {
  border-left: 0px solid transparent;
}
.contact-number-input >>> .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0px solid transparent;
  min-width: 60px;
  margin-left: 0;
}
.form-group.is-focus:disabled >>> label,
.form-group.is-focus.is-disabled >>> label.form-label {
  color: var(--prime-gray);
}
.b-form-btn-label-control.form-control[aria-disabled="true"] {
  background-color: transparent;
}
/* .datepicker >>> label.form-control {
    color: var(--prime-gray);
} */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
  .footer-img {
    max-width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .version-number {
    text-align: center;
    font-size: 0.7rem;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .setUpBtn {
    margin: 2px;
  }
}
</style>
