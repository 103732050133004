<template>
  <!-- order items modal -->
  <b-modal id="reset-account-row-modal" ref="resetItemModal" title="Are you sure you want to delete all of your data?"
    size="lg" header-class="font-primary" header-bg-variant="white" header-text-variant="secondary"
    header-border-variant="white" footer-bg-variant="gray-300" footer-border-variant="light" @hide="closeModal()">
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        ">
        <FormButton variant="light" isBlock class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel">Cancel
        </FormButton>
        <FormButton variant="danger" isBlock :disabled="!isDeleteButtonEnabled"
          :class="{ 'is-disabled': !isDeleteButtonEnabled }" :isLoading="isLoading"
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white" type="button" :loadingText="buttonText"
          @click.native="deleteFn">DELETE
        </FormButton>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon icon="times" class="text-secondary" @click="closeModal()"
        aria-label="Close Modal"></font-awesome-icon>
    </template>
    <p style="color: red; font-weight: 700; font-size: large;" class="text-left font-bold mb-4">
      Are you sure you want to permanently delete all of your data? This action cannot be undone.
    </p>
    <div>
      A CSV file of your donor data will be downloaded for your records.
    </div>
    <hr>
    <b-row class="mt-4 d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
      <p class="mb-2">Type <b> DELETE </b> to confirm.</p>
      <input v-model="enteredSecurityCode" type="text" class="form-control" :class="{ 'is-invalid': securityCodeError }"
        placeholder="" />
      <div class="invalid-feedback" v-if="securityCodeError">
        Please enter the text exactly as displayed to confirm.
      </div>
    </b-row>
  </b-modal>
</template>

<script>
// services
import FormButton from "@/components/Form/FormButton";
// import { DeleteAdmin } from "@/services/admin.service";
import { ExportCsv } from "@/services/donor.service";
import { DeleteCampaignResources } from "@/services/admin.service";
export default {
  name: "DeleteAdmin",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      securityCode: '',
      enteredSecurityCode: '',
      buttonText: "DELETING"
    };
  },
  components: {
    FormButton,
  },
  methods: {
    generateRandomCode() {
      return "DELETE"
      // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      // let randomCode = '';
      // for (let i = 0; i < length; i++) {
      //   const randomIndex = Math.floor(Math.random() * characters.length);
      //   randomCode += characters[randomIndex];
      // }
      // return randomCode;
    },
    isAuthFresh(freshnesSeconds = 30) {
      const now = Date.now() / 1000
      const auth_time = this.$keycloak.idTokenParsed.auth_time
      return now - auth_time < freshnesSeconds
    },
    async exportUsers() {
      const res = await ExportCsv();
      const data = res.data;
      const hiddenElement = document.createElement("a");
      const timeStamp = new Date().toLocaleDateString().replaceAll("/", "-");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      hiddenElement.target = "_blank";
      hiddenElement.download = `Donors-Export-${timeStamp}.csv`;
      hiddenElement.click();
    },

    async deleteFn() {
      this.isLoading = true;
      try {
        if (this.securityCodeError)
          return

        if (!this.isAuthFresh(60)) {
          this.$keycloak.login({
            maxAge: 1,
          })
        } else {
          this.buttonText = "Exporting Donor Data"
          await this.exportUsers()

          let payloadNotify = {
            isToast: true,
            title: "Permanently deleted",
            content: "Permanently deleted all of your data. This action cannot be undone.",
            variant: "success",
          };
          try {
            this.buttonText = "Deleting"
            await DeleteCampaignResources()

            this.$store.dispatch("notification/setNotify", payloadNotify);
          } catch (error) {
            if (error.status != 200) {
              console.log(error, "Error in deleteging ")
              payloadNotify = {
                isToast: true,
                title: "Failed",
                content: "Account data deletion failed. Please try again later.",
                variant: "danger",
              };
            }
          }
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.closeModal();
      } catch (error) {
        console.error("Error deleting account:", error);
      } finally {
        this.closeModal();
      }
    },

    closeModal() {
      this.isLoading = false;
      this.$emit("onClose");
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
  },
  created() {
    this.securityCode = this.generateRandomCode(5);
  },
  computed: {
    securityCodeError() {
      return this.securityCode !== this.enteredSecurityCode;
    },
    isDeleteButtonEnabled() {
      return !this.isLoading && !this.securityCodeError;
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
