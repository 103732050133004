<template>
  <b-col>
    <div v-if="payCard != null">
      <hr />
      <h6 class="text-start">Pay with Paypal</h6>
      <h2 class="text-start font-weight-bolder" v-if="this.clickApply == true">
        {{ this.totalAmountAfterCoupon }}
      </h2>
      <h2 class="text-start font-weight-bolder" v-if="this.clickApply == false">
        {{ payCardProps.user.amount }}
      </h2>
      <div class="container">
        <div class="row mt-3 mb-4">
          <div class="col-sm">{{ ProjectName }} setup fee</div>
          <div class="col-sm text-right">{{ payCardProps.user.amount }}</div>
        </div>
        <div class="row">
          <div class="col-sm">Discount</div>
          <div class="col-sm text-right" v-if="this.clickApply == true">
            {{ this.discounted }}
          </div>
          <div class="col-sm text-right mb-4" v-if="this.clickApply == false">
            $0.00
          </div>
        </div>
        <div class="row mb-2">
          <div class="col" v-if="this.clickApply == true">
            <p class="text-black-50 text">
              {{ this.percentOff }}% discount applied.
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm">Subtotal</div>
          <div class="col-sm text-right" v-if="this.clickApply == true">
            {{ this.totalAmountAfterCoupon }}
          </div>
          <div class="col-sm text-right" v-if="this.clickApply == false">
            {{ payCardProps.user.amount }}
          </div>
        </div>
      </div>
      <hr />
      <div v-if="this.clickApply == false">
        <FormInputGroup
          groupId="password-group"
          id="promocode"
          v-model="coupon"
          :state="couponError.status"
          :invalidFeedback="`${couponError.message}`"
          label="Coupon"
          placeholder="Coupon"
          isRequired
          form="admin-registration-form"
          isAppend
        >
          <template v-slot:append>
            <FormButton
              @click.native="submitFn"
              :isLoading="couponLoading"
              class="btnApply submitBtn px-3 px-md-4 text-primary key-row bg-light"
              variant="transparent"
              type="submit"
              >Apply</FormButton
            >
          </template>
        </FormInputGroup>
      </div>
    </div>

    <div v-if="paymentInitialBtn">
      <hr />
      <b-row
        class="d-flex flex-row align-items-center justify-content-center w-100"
        no-gutters
      >
        <b-col
          class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
          cols="9"
          sm="6"
        >
          <FormButton
            variant="primary"
            isBlock
            class="font-secondary font-weight-normal text-white mt-2 submitBtn w-100"
            type="submit"
            :isLoading="setupFeeLoading"
            @click.native="paySetupFee"
            :isDisabled="this.setupFeePaid || this.forceDisable"
            >{{
              this.setupFeePaid ? "Subscribed" : "Proceed to Payment"
            }}</FormButton
          >
        </b-col>
      </b-row>
    </div>

    <b-row>
      <component
        :is="payCard"
        v-bind="payCardProps"
        @payment_done="onPaymentDone"
      >
      </component>
    </b-row>
  </b-col>
</template>

<script>
import FormButton from "@/components/Form/FormButton";
import FormInputGroup from "@/components/Form/FormInputGroup";
import {
  PaySetupFee,
  ReturningFromPayment,
  GetCouponsDetails,
} from "@/services/settings.service";
import PayWithPayPalAdvanced from "@/components/Settings/Payments/PayWithPayPalAdvanced";

export default {
  name: "PayWithPayPal",
  components: {
    FormButton,
    PayWithPayPalAdvanced,
    FormInputGroup,
  },
  data() {
    return {
      ProjectName: null,
      payCardProps: null,
      payCard: null,
      paymentInitialBtn: true,
      setupFeeLoading: false,
      couponLoading: false,
      discounted: null,
      percentOff: null,
      totalAmount: null,
      totalAmountAfterCoupon: null,
      clickApply: false,
      coupon: null,
      couponError: {
        status: null,
        message: "",
      },
      isReset: false,
    };
  },
  props: {
    setupFeePaid: {
      type: Boolean,
      default: undefined,
    },
    forceDisable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    coupon(val) {
      if (!this.isReset) {
        this.couponError.status = null;
        if (val != null) {
          this.couponError.status = true;
          if (!val) {
            this.couponError.status = false;
            this.couponError.message = "Please fill in the field";
          }
        }
      }
    },
  },
  created() {
    console.log(window.location);
    if (this.$route.query.token) {
      this.setupFeeLoading = true;
      ReturningFromPayment({
        status: this.$route.query.status,
        session: this.$route.query.token,
        payer: this.$route.query.PayerID,
      }).then((res) => {
        if (res.status == 200) {
          window.location = window.location.pathname;
        } else {
          console.log("Nothing changed");
        }
      });
    }
  },
  mounted() {
    this.ProjectName = process.env.VUE_APP_PROJECT_TITLE;
  },
  computed: {},
  methods: {
    onPaymentDone(status) {
      console.log("payment status", status);
      this.setupFeeLoading = false;
      this.setupFeePaid = true;
      this.payCard = null;
      this.paymentInitialBtn = true;
      // window.location.reload();
    },

    errorFn() {
      var errorList = [];

      if (!this.coupon) {
        this.couponError.status = false;
        this.couponError.message = "Please fill in the coupon";
        errorList.push(this.couponError.message);
      } else this.couponError.status = true;
    },
    paySetupFee() {
      this.setupFeeLoading = true;
      PaySetupFee()
        .then((res) => {
          if (res.data.content.method == "advanced") {
            this.paymentInitialBtn = false;
            this.payCard = "PayWithPayPalAdvanced";
            this.payCardProps = res.data.content.props;
          } else {
            // If normal method redirect to paypal site.
            window.location.replace(res.data.content.redirect);
          }
        })
        .catch((er) => {
          window.location.replace(er.data.content.redirect);
        })
        .finally(() => {
          this.setupFeeLoading = true;
        });
    },

    async submitFn() {
      var errors = this.errorFn();
      if (errors && errors.length > 0) {
        // TODO : Show validation error msg
        return (this.couponError.message = "Invalid Code");
      }
      try {
        this.couponLoading = true;
        let { data } = await GetCouponsDetails(
          document.getElementById("promocode").value,
          this.payCardProps.gateway.orderId
        );
        // TODO : Catch request failed errors and show error msg.

        if (data.code == 200 || data.message == "success") {
          this.clickApply = true;
          this.discounted = data.content.discounted;
          this.percentOff = data.content.percentOff;
          this.totalAmount = data.content.totalAmount;
          this.totalAmountAfterCoupon = data.content.totalAmountAfterCoupon;
        } else {
          this.couponError.status = false;
          this.couponError.message = "Invalid Code";
        }
        this.couponLoading = false;
      } catch (error) {
        // TODO : Show error message
        this.couponLoading = false;
        this.couponError.status = false;
        this.couponError.message = "Invalid Code. Please try again";
        console.log("ERROR");
      }
    },
  },
};
</script>

<style scoped>
.promo {
  border: 1px solid grey;
  padding: 2px;
  width: 95%;
  border-radius: 6px;
  margin-left: 3%;
}

input {
  flex-grow: 2;
  border: none;
}

input:focus {
  outline: none;
}
.btnApply {
  color: #b22234;
  background-color: #fff;
  box-shadow: none;
}

.text {
  font-size: 13px;
}
</style>
