<template>
  <div>
    <b-alert
      v-for="(message, index) in messages"
      :key="index"
      class="m-4"
      show
      :variant="message.type"
    >
      <div v-html="message.html"></div>
    </b-alert>
    <!-- failed component -->
    <div class="d-flex justify-content-center">
      <div v-if="this.connection_status === 'active'">
        <b-col class="px-1">
          <FormButton
            variant="primary"
            isBlock
            class="font-weight-normal text-white mt-2 border-dark"
            type="submit"
            >Disable</FormButton
          >
        </b-col>
      </div>
    </div>

    <!-- pending component -->
    <div v-if="this.connection_status === 'pending'">
      <b-container>
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" v-if="showFormInput">
            <FormInput
              groupId="Aristotle-name-group"
              id="Aristotle-name-group-input"
              class="text-prime-gray"
              label="Aristotle key"
              isRequired
              trim
              form="Aristotle-form"
              type="text"
            ></FormInput>
          </b-col>

          <b-col
            class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn"
            cols="12"
          >
            <b-row class="w-100 no-gutters">
              <b-col class="px-1">
                <FormButton
                  variant="primary"
                  isBlock
                  class="font-weight-normal text-white mt-2 border-dark"
                  type="submit"
                  >I have received it</FormButton
                >
              </b-col>
              <b-col class="px-1">
                <FormButton
                  variant="primary"
                  isBlock
                  class="font-weight-normal text-white mt-2 border-dark"
                  type="submit"
                  >I have not received it</FormButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
  
  <script>
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";

export default {
  name: "ConnectionStatus",
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      onboardLoading: false,
      messages: [],
      forceMsg: false,
      showDisconnectDirections: false,
      disconnected: false,
      loadingDisconnect: false,
      aristotleKey: "",
      showFormInput: false,
    };
  },
  props: {
    connection_status: {
      type: String,
    },
    forceDisable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    console.log("propsmessage", this.connection_status);

    if (this.connection_status === "pending") {
      this.messages.push({
        html: "We have created a sample donation in your Aristotle account. Please check and confirm you have received it.",
        type: "success",
      });
    } else if (this.connection_status === "Failed") {
      this.messages.push({
        html: "Please check",
        type: "success",
      });
    } else if (this.connection_status === "active") {
      this.messages.push({
        html: "Aristotle is connected.",
        type: "success",
      });
    }

    this.forceMsg = true;
  },
};
</script>
  
  <style scoped>
.setUpBtn {
  margin: 2px;
}
</style>
  