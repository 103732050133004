<template>
  <!-- order items modal -->
  <b-modal
    id="password-row-modal"
    ref="EditItemModal"
    title="Password settings"
    size="md"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          isBlock
          :disabled="apiLoading"
          :isLoading="apiLoading"
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
          type="button"
          @click.native="resetPwdFn"
          >Update</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <!-- Password Settings form -->
    <form
      id="reset-password-form"
      class="d-flex flex-column align-items-start justify-content-start w-100"
    >
      <!-- full row input (Password) -->
      <b-row
        class="
          rounded=0
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
        "
        no-gutters
      >
        <b-col cols="12">
          <FormInputGroup
            groupId="password-group"
            id="password-group-input"
            v-model="oldPassword"
            label="Old Password"
            placeholder="Minimum 6 characters"
            isRequired
            :state="oldPasswordError.status"
            :invalidFeedback="`${oldPasswordError.message}`"
            :type="oldPasswordShow ? 'text' : 'password'"
            form="reset-password-form"
            isAppend
          >
            <template v-slot:append>
              <b-button
                class="
                  px-3 px-md-4
                  icon-btn
                  border border-gray
                  overflow-hidden
                  shadow-sm
                "
                variant="transparent"
                @click="oldPasswordShow = !oldPasswordShow"
              >
                <font-awesome-icon
                  class="text-prime-gray"
                  :icon="oldPasswordShow ? 'eye-slash' : 'eye'"
                  aria-label="Help"
                ></font-awesome-icon>
              </b-button>
            </template>
          </FormInputGroup>
        </b-col>
      </b-row>
      <!-- full row input (Password) -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12">
          <FormInputGroup
            groupId="password-group"
            id="password-group-input"
            v-model="newPassword"
            label="New Password"
            placeholder="Minimum 6 characters"
            isRequired
            :state="newPasswordError.status"
            :invalidFeedback="`${newPasswordError.message}`"
            :type="newPasswordShow ? 'text' : 'password'"
            form="reset-password-form"
            isAppend
          >
            <template v-slot:append>
              <b-button
                class="
                  px-3 px-md-4
                  border border-gray
                  overflow-hidden
                  shadow-sm
                "
                variant="transparent"
                @click="newPasswordShow = !newPasswordShow"
              >
                <font-awesome-icon
                  class="text-prime-gray"
                  :icon="newPasswordShow ? 'eye-slash' : 'eye'"
                  aria-label="Help"
                ></font-awesome-icon>
              </b-button>
            </template>
          </FormInputGroup>
        </b-col>
      </b-row>
      <!-- full row input (Password) -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12">
          <FormInputGroup
            groupId="password-group"
            id="password-group-input"
            v-model="confirmNewPassword"
            label="Confirm New Password"
            placeholder="Minimum 6 characters"
            isRequired
            :state="confirmNewPasswordError.status"
            :invalidFeedback="`${confirmNewPasswordError.message}`"
            :type="confirmNewPasswordShow ? 'text' : 'password'"
            form="reset-password-form"
            isAppend
          >
            <template v-slot:append>
              <b-button
                class="
                  px-3 px-md-4
                  border border-gray
                  overflow-hidden
                  shadow-sm
                "
                variant="transparent"
                @click="confirmNewPasswordShow = !confirmNewPasswordShow"
              >
                <font-awesome-icon
                  class="text-prime-gray"
                  :icon="confirmNewPasswordShow ? 'eye-slash' : 'eye'"
                  aria-label="Help"
                ></font-awesome-icon>
              </b-button>
            </template>
          </FormInputGroup>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<script>
// services
import FormButton from "@/components/Form/FormButton";
// import { DeleteAdmin } from "@/services/admin.service";
import FormInputGroup from "@/components/Form/FormInputGroup";
import { CreatePassword } from "@/services/passwords.service";

export default {
  name: "DeleteAdmin",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    // itemIndex: {
    //   default: null,
    //   type: Number,
    // },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      apiLoading: false,
      nameError: {
        status: null,
        message: "",
      },
      name: null,
      urlError: {
        status: null,
        message: "",
      },
      url: null,
      contactNoError: {
        status: null,
        message: "",
      },
      contactNo: null,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
      isReset: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
    };
  },
  components: {
    FormButton,
    FormInputGroup,
  },
  watch: {
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmNewPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmNewPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmNewPasswordError.status = true;
            } else {
              this.confirmNewPasswordError.status = false;
              this.confirmNewPasswordError.message = "Passwords do not match";
            }
          }
        } else {
          this.confirmNewPasswordError.status = false;
          this.confirmNewPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
  },
  methods: {
    async resetPwdFn() {
      // e.preventDefault();
      try {
        let errorList = this.errorFn();
        if (errorList.length == 0) {
          // if (
          //   this.oldPassword &&
          //   this.newPassword &&
          //   this.confirmNewPassword &&
          //   this.newPassword == this.confirmNewPassword
          // ) {
          let payload = {
            oldPassword: this.oldPassword,
            password: this.newPassword,
          };
          this.apiLoading = true;
          let { data, status } = await CreatePassword(payload);
          if (data && status == 200) {
            let payloadNotify = {
              isToast: true,
              title: "Password Change",
              content: "Your password has been changed successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.resetFn();
            this.$emit("reloadFn");
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Password Reset",
              content:
                data && data.msg
                  ? data.msg
                  : "Unable to reset password. Please try again",
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } else {
          // form validatation error
          let payloadNotify = {
            isToast: true,
            title: `ERROR: Unable to Update Password`,
            content: `Unable to Update Password`,
            list: errorList,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let title = "ERROR! Password Reset";
        let msg = "Unable to reset password. Please try again.";
        if (error && error.response && error.response.data) {
          msg = error.response.data.msg;
        }
        let payloadNotify = {
          isToast: true,
          title: title,
          content: msg,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.apiLoading = false;
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
      this.oldPasswordError.status = null;
      this.newPasswordError.status = null;
      this.confirmNewPasswordError.status = null;
    },
    errorFn() {
      var errorList = [];
      if (!this.oldPassword) {
        this.oldPasswordError.status = false;
        this.oldPasswordError.message = "Please fill in your old password";
        errorList.push(this.oldPasswordError.message);
      }
      if (!this.newPassword) {
        this.newPasswordError.status = false;
        this.newPasswordError.message = "Please fill in your new password";
        errorList.push(this.newPasswordError.message);
      } else if (
        (!this.newPassword && this.newPassword.length < 6) ||
        (this.newPassword &&
          this.newPassword.length > 0 &&
          this.newPassword.length < 6)
      ) {
        this.newPasswordError.status = false;
        this.newPasswordError.message =
          "Password must contain at least 6 characters";
        errorList.push(this.newPasswordError.message);
      }
      if (!this.confirmNewPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message =
          "Please fill in your new password again";
        errorList.push(this.confirmNewPasswordError.message);
      } else if (this.confirmNewPassword != this.newPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message = "Passwords do not match";
        errorList.push(this.confirmNewPasswordError.message);
      }
      return errorList;
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
