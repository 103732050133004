<template>
  <HomeLayout>
    <b-container class="d-flex flex-column align-items-start justify-content-start px-0">
      <!-- card container row -->
      <b-row class="d-flex flex-row align-items-stretch justify-content-center w-100 card-row" no-gutters>
        <b-card no-body class="overflow-hidden border border-light shadow-sm w-100 card-container">
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- admin details row -->
              <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
                <b-col cols="6" sm="6" class="d-flex flex-column align-items-start justify-content-center">
                  <h6 class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3">
                    Account details
                  </h6>
                </b-col>
                <b-col cols="6" sm="6" class="d-flex flex-column align-items-end align-items-sm-end justify-content-end">
                  <b-row class="mx-n1">
                    <b-button size="sm"
                      class="d-flex flex-row align-items-center justify-content-center shadow-none border-primary edit-btn mx-2 my-1"
                      pill variant="white" @click="openPasswordModal($event.target)">
                      <font-awesome-icon icon="key" aria-label="Delete User"
                        class="px-0 w-auto edit-icon text-primary"></font-awesome-icon>
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
                <!-- campaign name -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary col-align">
                    Charity Name :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ campaignName }}
                  </span>
                </div>
                <!-- admin fullName -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary col-align">
                    Treasurer’s Full Name :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ adminName }}
                  </span>
                </div>
                <!-- admin email -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Treasurer’s Email :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ adminEmail }}
                  </span>
                </div>
                <!-- contact Number -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Contact No :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ contactNumber ? contactNumber : "N/A" }}
                  </span>
                </div>
                <!-- admin joined date -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Joined Date :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ createdAt ? createdAt.split("T")[0] : "06/08/2021" }}
                  </span>
                </div>
                <!-- Federal Tax Id -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary">
                    Federal Tax ID :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ FederalTaxId }}
                  </span>
                </div>
                <!-- campaign Website URL -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary col-align">
                    Charity Website URL :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    {{ campaignWebsiteURL }}
                  </span>
                </div>
                <!-- campaign donation page URL -->
                <div class="inner-row-grid py-2 w-100">
                  <span class="text-left text-prime-gray font-secondary col-align">
                    Donation Page :
                  </span>
                  <span class="text-left text-dark font-secondary">
                    <a target="_blank" :href="campaignDonationPage">{{
                      campaignDonationPage
                    }}</a>
                  </span>
                </div>
              </b-row>
              <hr class="border-bottom-1 row-divider" />
              <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
                <b-col cols="6" sm="6" class="d-flex flex-column align-items-start justify-content-center mb-3">
                  <h6 class="text-left text-dark font-weight-semi-bold font-primary mt-3">
                    Minimum contribution value
                  </h6>
                </b-col>
                <b-col cols="12">
                  <FormInput groupId="minVaue-name-group" id="minVaue-name-group-input" class="text-prime-gray"
                    :state="minValueError.status" :invalidFeedback="`${minValueError.message}`" label="Set Minimum Value"
                    v-model="minValue" isRequired trim @input="onKeypressDollorSign()" form="admin-registration-form"
                    type="text" autocomplete="number"></FormInput>
                </b-col>
              </b-row>
              <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
                <b-col class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn" cols="6">
                  <FormButton variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark"
                    type="submit" @click.native="setMinimumValue" :isLoading="isMinValueLoading" :isDisabled="!minValue">
                    Submit</FormButton>
                </b-col>
              </b-row>
              <hr class="border-bottom-1 row-divider" />
              <component :is="paymentAccountOptionsComponent" v-bind="paymentAccountProps"></component>
              <hr class="border-bottom-1 row-divider" />
              <DeleteData></DeleteData>
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-1 py-3 right-column">
              <b-row class="mb-3">
                <b-col cols="12" sm="6" class="d-flex flex-column align-items-start justify-content-center">
                  <h6 class="text-left text-dark font-weight-semi-bold font-primary mt-3">
                    Additional account details
                  </h6>
                </b-col>

                <b-col cols="12" sm="6"
                  class="d-flex flex-column align-items-center align-items-sm-end justify-content-end">
                  <b-row class="mx-n1">
                    <FormButton v-if="canRetain" variant="primary" isBlock
                      class="font-weight-normal text-white mt-2 border-dark" type="submit" @click.native="retainAccount"
                      :isLoading="canRetainLoading">Retain Account</FormButton>
                  </b-row>
                </b-col>
              </b-row>
              <!-- Additional account details form -->
              <form id="reset-password-form"
                class="d-flex flex-column align-items-start justify-content-start w-100 mt-4">
                <template>
                  <b-row class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
                    <b-col cols="12">
                      <FormInput v-for="charity in charityInputFelids" :key="charity.id" :id="charity.id"
                        :label="charity.label" :groupId="charity.groupId" :class="charity.class" :type="charity.type"
                        :name="charity.name" :trim="charity.trim" :state="inputFelidValues[charity.name + 'Error'].status"
                        v-model="inputFelidValues[charity.name]" :invalidFeedback="inputFelidValues[charity.name + 'Error'].message
                          " :isDisabled="isInfoSubmitted">
                      </FormInput>
                    </b-col>
                  </b-row>
                  <b-row class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
                    <b-col cols="12">
                      <FormInput v-for="commonValue in charityInputFelidsCommon" :key="commonValue.id"
                        :id="commonValue.id" :label="commonValue.label" :groupId="commonValue.groupId"
                        :class="commonValue.class" :type="commonValue.type" :name="commonValue.name"
                        :trim="commonValue.trim" :state="inputFelidValues[commonValue.name + 'Error'].status
                          " v-model="inputFelidValues[commonValue.name]" :invalidFeedback="inputFelidValues[commonValue.name + 'Error'].message
    " :isDisabled="isInfoSubmitted">
                      </FormInput>
                    </b-col>
                  </b-row>
                </template>

                <hr class="border-bottom-1 row-divider" />

                <b-row class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 px-3" no-gutters>
                  <b-form-checkbox v-model="statementDeclare" name="checkbox-1" class="text-center small"
                    :disabled="isInfoSubmitted ? true : false">
                    I hereby declare under penalty of perjury that the
                    information above is true and correct and that I am
                    authorized by the above-named charity to create this
                    account.
                  </b-form-checkbox>
                </b-row>
                <b-row class="d-flex flex-row align-items-start justify-content-start w-100 py-2" no-gutters>
                  <div v-if="errorStatementDeclare.status == false" class="alert alert-danger alert-dismissible fade show"
                    role="alert">
                    {{ errorStatementDeclare.message }}
                    <button @click="errorStatementDeclare.status = null" type="button" class="close" data-dismiss="alert"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </b-row>

                <b-row class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 px-3 mt-4"
                  no-gutters>
                  <b-form-checkbox v-model="acceptTermAndConditon" name="checkbox-2" class="text-center small"
                    :disabled="isInfoSubmitted ? true : false">
                    I accept the
                    <a target="_blank" @click.stop :href="TermsPage">terms & conditions</a>
                    &
                    <a target="_blank" @click.stop :href="PrivacyPage">privacy policy</a>
                    of this platform.
                  </b-form-checkbox>
                </b-row>

                <b-row class="d-flex flex-row align-items-start justify-content-start w-100 py-2" no-gutters>
                  <div v-if="errorAcceptTermAndConditon.status == false"
                    class="alert alert-danger alert-dismissible fade show" role="alert">
                    {{ errorAcceptTermAndConditon.message }}
                    <button @click="errorAcceptTermAndConditon.status = null" type="button" class="close"
                      data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </b-row>

                <b-row class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-5" no-gutters>
                  <b-col cols="12">
                    <FormInput groupId="declarationName-name-group" id="declarationName-name-group-input"
                      class="text-prime-gray" :state="declarationNameError.status"
                      :invalidFeedback="`${declarationNameError.message}`" label="Declaration Name"
                      v-model="declarationName" isRequired trim :isDisabled="isInfoSubmitted"
                      form="admin-registration-form" type="text"></FormInput>
                  </b-col>
                </b-row>

                <!-- form action -->
                <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
                  <b-col class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn" cols="6">
                    <FormButton variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark"
                      type="submit" @click.native="AdditionalInfoCreate" :isLoading="isLoading"
                      :isDisabled="!canUpdateInfo || !disableButtons">Submit</FormButton>
                  </b-col>
                </b-row>
                <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
                  <component :is="gatewayComponent" v-bind="setupFeeProps"></component>
                </b-row>
              </form>
              <!-- PayPal Logo -->
              <b-row>
                <table border="0" cellpadding="0" cellspacing="0" align="center">
                  <tr>
                    <td align="center"></td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works"
                        onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;">
                        <img src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg" border="0"
                          alt="PayPal Acceptance Mark" style="width: 260px" />
                      </a>
                    </td>
                  </tr>
                </table>
              </b-row>
              <!-- PayPal Logo -->
            </b-col>
          </b-row>
          <b-row class="d-flex flex-row align-items-center justify-content-center px-3 pt-2 pt-sm-0 pb-1 pb-sm-2"
            no-gutters>
            <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
              <h5 class="version-number mb-0 text-muted text-center w-100">
                {{ this.version }}
              </h5>
            </b-col>
          </b-row>
          <!-- overlay loader -->
          <b-overlay :show="isLoading" class="mx-n3" no-wrap></b-overlay>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
// import FormInputGroup from "@/components/Form/FormInputGroup";
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";

// import DeleteAdmin from "@/components/Settings/AccountDeleteModal";
import EditAdmin from "@/components/Settings/AccountEditModal";
import PasswordSettings from "@/components/Settings/PasswordSettings";
import PayWithPayPal from "@/components/Settings/Payments/PayWithPayPal";
import PayPalAccount from "@/components/Settings/Payments/PayPalAccount";
import Vue from "vue";

// services
import {
  GetDetails,
  RetainAccount,
  PaySetupFee,
  CreateAdditionalInfo,
  GetAdditionalInfo,
} from "../services/settings.service";
import { CreateLayout } from "../services/campaignTemplate.service";
//import data
import FormRadioButtonVue from "../components/Form/FormRadioButton.vue";
import DeleteData from "@/components/Settings/DeleteData/DeleteData.vue";

//give proudly imports
import {
  charity,
  giveProudlyInputFelidsCommon,
} from "../data/giveProudly/giveProudly";
export default {
  name: "Settings",
  components: {
    HomeLayout,
    // FormInputGroup,
    FormButton,
    FormInput,
    // DeleteAdmin,
    DeleteData,
    EditAdmin,
    PasswordSettings,
    PayWithPayPal,
    PayPalAccount,
    FormRadioButtonVue,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 12);
    // minDate.setDate(31)
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 12);
    // maxDate.setDate(31)
    return {
      accountDetails: {
        firstName: "john",
        email: "john@example.com",
        adminType: "Administrator",
      },
      inputFelidValues: {
        identificationNo: null,
        identificationNoError: {
          status: null,
          message: "",
        },
        mailingAdress: "",
        mailingAdressError: {
          status: null,
          message: "",
        },
        contactNo: "",
        contactNoError: {
          status: null,
          message: "",
        },
      },
      // formCheckBox: {
      //   ,
      // },

      campaignID: null,
      contactNumber: null,
      FederalTaxId: null,
      adminId: null,
      isReset: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
      apiLoading: false,
      campaignWebsiteURL: null,
      campaignName: null,
      createdAt: null,
      isLoading: false,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
      totalRows: 500,
      isShowDeleteAdminModal: false,
      isShowEditAdminModal: false,
      isShowPassowrdModal: false,
      showDeleteAdminModalKey: Number(new Date()) + 110,
      deleteItem: null,
      editItem: null,
      passwordItem: null,
      adminName: null,
      adminEmail: null,
      campaignDonationPage: null,
      TermsPage: null,
      PrivacyPage: null,
      // Payments
      canLogin: undefined,
      setupFeePaid: undefined,
      onboardingCompleted: undefined,
      canUpdateStripe: undefined,
      canRetain: false,
      canRetainLoading: false,
      showPwModalKey: Number(new Date()) + 100,
      loginLoading: undefined,
      stripeOnboardLoading: undefined,
      setupFeeLoading: undefined,
      minValueError: {
        status: null,
        message: "",
      },
      minValue: null,
      selectedCandidate: "federalCandidate",
      candidateOptions: [
        { text: "Federal Candidate", value: "federalCandidate" },
        { text: "Federal PAC", value: "federalPAC" },
        { text: "State/Local Candidate bar", value: "candidateBar" },
        { text: "State PAC/501(c)4", value: "statePAC" },
      ],
      selectedParty: "republican",
      partyOptions: [
        { text: "Republican", value: "republican" },
        { text: "Democrat", value: "democrat" },
        { text: "Other", value: "other" },
      ],
      //  partyOptionsState: [
      //   { text: "Republican", value: "republican" },
      //   { text: "Democrat", value: "democrat" },
      // ],
      selectedRace: "house",
      raceInfoOptions: [
        { text: "House", value: "house" },
        { text: "Senate", value: "senate" },
        { text: "Presidential", value: "presidential" },
      ],

      selectElection: "primary",
      electionOptions: [
        { text: "Primary", value: "primary" },
        { text: "General", value: "general" },
      ],
      dateOfElection: null,
      dateOfElectionError: {
        status: null,
        message: "",
      },
      statementDeclare: false,
      acceptTermAndConditon: false,
      declarationName: null,
      declarationNameError: {
        status: null,
        message: "",
      },
      errorAcceptTermAndConditon: {
        status: null,
        message: "",
      },
      errorStatementDeclare: {
        status: null,
        message: "",
      },
      isMinValueLoading: false,
      isInfoSubmitted: false,
      canUpdateInfo: false,
      disableButtons: false,
      min: minDate,
      max: maxDate,
      setupFeeProps: undefined,
      gatewayComponent: undefined,
      gatewayComponentKeys: {
        PAYPAL: "PayWithPayPal",
        // PAYPAL: "PayWithPayPalAdvanced",
      },
      paymentAccountComponentKeys: {
        PAYPAL: "PayPalAccount",
      },
      paymentAccountOptionsComponent: undefined,
      paymentAccountProps: undefined,
    };
  },

  computed: {
    /* -------------------------------------------------------------------------- */
    /*                                Give Proudly Configuration                                */
    /* -------------------------------------------------------------------------- */
    charityInputFelids() {
      return charity;
    },
    charityInputFelidsCommon() {
      return giveProudlyInputFelidsCommon;
    },
  },

  // getModelData() {
  //   console.log(this.formatData.candidateName);
  //   return this.candidateName == this.formatData.candidateName;
  // },

  mounted() {
    this.adminName = Vue.$keycloak.idTokenParsed.name;
    this.adminEmail = Vue.$keycloak.idTokenParsed.email;
    //this.firstName = Vue.GetDetails.name;
    // this.joinedDate = Vue.$keycloak.idTokenParsed.
    // this.type = Vue.$keycloak.idTokenParsed.
    this.getSettingDetails();
    // this.getDetails();
    this.initFn();
  },
  watch: {
    /* ----------------------------------- validation change the status ---------------------------------- */
    "inputFelidValues.mailingAdress"(val) {
      if (val) {
        this.inputFelidValues.mailingAdressError.status = true;
      } else {
        this.inputFelidValues.mailingAdressError.status = false;
        this.inputFelidValues.mailingAdressError.message =
          "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.inputFelidValues.mailingAdressError.status = null;
      }
    },
    "inputFelidValues.identificationNo"(val) {
      if (val) {
        this.onKeypressIdentification();
        this.inputFelidValues.identificationNoError.status = true;
      }
    },

    "inputFelidValues.contactNo"(val) {
      if (val) {
        this.inputFelidValues.contactNoError.status = true;
      } else {
        this.inputFelidValues.contactNoError.status = false;
        this.inputFelidValues.contactNoError.message =
          "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.inputFelidValues.contactNoError.status = null;
      }
    },
    declarationName(val) {
      if (val) {
        this.declarationNameError.status = true;
      } else {
        this.declarationNameError.status = false;
        this.declarationNameError.message = "Please fill in the field";
      }
      if (this.isInfoSubmitted) {
        this.declarationNameError.status = null;
      }
    },

    // selectedCandidate: function () {
    //   this.change;
    //   this.$forceUpdate(); // trigger the computed property to recalculate
    // },
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmNewPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmNewPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmNewPasswordError.status = true;
            } else {
              this.confirmNewPasswordError.status = false;
              this.confirmNewPasswordError.message = "Passwords do not match";
            }
          }
        } else {
          this.confirmNewPasswordError.status = false;
          this.confirmNewPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
    minValue(val) {
      var newVal = val.slice(1);
      if (newVal) {
        this.minValueError.status = true;
      } else {
        this.minValueError.status = false;
        this.minValueError.message = "Please fill in the field";
      }
    },

    statementDeclare() {
      this.errorStatementDeclare.message = null;
      this.errorStatementDeclare.status = true;
    },
    acceptTermAndConditon() {
      this.errorAcceptTermAndConditon.message = null;
      this.errorAcceptTermAndConditon.status = true;
    },
  },
  methods: {
    // TODO Check why response ends up in error block
    getValue(v) {
      console.log(v);
    },

    retainAccount() {
      this.canRetainLoading = true;

      RetainAccount()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          console.log("ERROR S02");
        })
        .finally(() => {
          this.canRetainLoading = false;
        });
    },

    paySetupFee() {
      this.setupFeeLoading = true;
      PaySetupFee()
        .then((res) => {
          window.location.replace(res.data.content.redirect);
        })
        .catch((er) => {
          window.location.replace(er.data.content.redirect);
        })
        .finally(() => {
          this.setupFeeLoading = true;
        });
    },
    // async getDetails() {
    //   try {
    //     this.isLoading = true;
    //     let { data } = await GetDetails();

    //     const details = data.content.campaign;

    //     this.campaignWebsiteURL = details.url ? details.url : "N/A";
    //     this.campaignName = details.name ? details.name : "N/A";
    //     // this.contactNumber = details.ownerContact
    //     //   ? details.ownerContact
    //     //   : "N/A";
    //     this.createdAt = details.created_at ? details.created_at : "N/A";
    //     this.campaignID = details.idName ? details.idName : "N/A";
    //   } catch (error) {
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },
    async getSettingDetails() {
      try {
        this.isLoading = true;
        const res = await GetDetails();

        // stripe data
        const data = res.data.content.gateway;

        this.setupFeeProps = {
          setupFeePaid: res.data.content.setupFeePaid,
          forceDisable: !this.isInfoSubmitted,
        };

        this.paymentAccountProps = {
          gatewayOptions: data,
          forceDisable: !res.data.content.setupFeePaid,
          campaign: res.data.content.campaign,
        };

        // Set Payment gateway options component key
        this.paymentAccountOptionsComponent =
          this.paymentAccountComponentKeys[
          res.data.content.prefferedPaymentGateway
          ];

        // Set setup fee component key
        this.gatewayComponent =
          this.gatewayComponentKeys[res.data.content.prefferedPaymentGateway];

        this.disableButtons = true;
        if (res.data.content.minimumDonation)
          this.minValue = "$ " + res.data.content.minimumDonation;
        this.campaignDonationPage = `${process.env.VUE_APP_WEB_URL}/${res.data.content.campaign.idName}`;
        this.TermsPage = `${process.env.VUE_APP_WEB_URL}/terms`;
        this.PrivacyPage = `${process.env.VUE_APP_WEB_URL}/privacy`;

        // campaign data
        const campaign = res.data.content.campaign;
        this.campaignWebsiteURL = campaign.url ? campaign.url : "N/A";
        this.campaignName = campaign.name ? campaign.name : "N/A";
        this.createdAt = campaign.created_at ? campaign.created_at : "N/A";
        this.campaignID = campaign.idName ? campaign.idName : "N/A";
        this.FederalTaxId = campaign.federalTaxId
          ? campaign.federalTaxId
          : "N/A";

        this.canUpdateInfo = res.data.content.canUpdateInfo;
      } catch (error) {
        console.log(error);
        console.log("ERROR S04", error);
      } finally {
        this.isLoading = false;
      }
    },
    async resetPwdFn() { },
    errorFn() {
      var errorList = [];
      if (!this.inputFelidValues.mailingAdress) {
        this.inputFelidValues.mailingAdressError.status = false;
        this.inputFelidValues.mailingAdressError.message =
          "Please fill in mailing address";
        errorList.push(this.inputFelidValues.mailingAdressError.message);
      }
      if (!this.inputFelidValues.contactNo) {
        this.inputFelidValues.contactNoError.status = false;
        this.inputFelidValues.contactNoError.message =
          "Please fill in contact no";
        errorList.push(this.inputFelidValues.contactNoError.message);
      }
      if (!this.declarationName) {
        this.declarationNameError.status = false;
        this.declarationNameError.message = "Please fill in declaration name";
        errorList.push(this.declarationNameError.message);
      }
      if (!this.statementDeclare) {
        this.errorStatementDeclare.status = false;
        errorList.push(
          (this.errorStatementDeclare.message =
            "Please tick Terms & Conditions & Privacy policy")
        );
      }

      if (!this.acceptTermAndConditon) {
        this.errorAcceptTermAndConditon.status = false;
        errorList.push(
          (this.errorAcceptTermAndConditon.message =
            "Please tick Declare Information")
        );
      }

      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
    },
    openDeleteItemModal(item, button) {
      this.isShowDeleteAdminModal = true;
      this.deleteItem = item;
      this.$root.$emit("bv::show::modal", "delete-account-row-modal", button);
    },
    openEditItemModal(item, button) {
      this.isShowEditAdminModal = true;
      this.editItem = item;
      this.$root.$emit("bv::show::modal", "edit-account-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteAdminModal = false;
    },
    onCloseEditItemModal() {
      this.isShowEditAdminModal = false;
    },
    openPasswordModal(item, button) {
      this.isShowPassowrdModal = true;
      this.passwordItem = item;
      this.$root.$emit("bv::show::modal", "password-row-modal", button);
    },
    onClosePasswordModal() {
      this.isShowPassowrdModal = false;
    },
    async AdditionalInfoCreate() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          candidateType: 'charity',
          identificationNo: this.inputFelidValues.identificationNo,
          campaignMailingAddress: this.inputFelidValues.mailingAdress,
          treasurerPhoneNumber: this.inputFelidValues.contactNo,
          declarationName: this.declarationName,
          acceptTermAndConditon: this.acceptTermAndConditon,
          statementDeclare: this.statementDeclare,
        };
        console.log(payload);
        this.isLoading = true;
        try {
          let { data } = await CreateAdditionalInfo(payload);
          if (data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Additional Account Infomation",
              content: `Account Details has been successfully saved`,
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.isInfoSubmitted = true;
            this.canUpdateInfo = false;
            this.initFn();

            this.inputFelidValues.identificationNoError.status = null;
            this.inputFelidValues.mailingAdressError.status = null;
            this.inputFelidValues.contactNoError.status = null;
            this.declarationNameError.status = null;

            setTimeout(() => this.$emit("reloadFn"), 600);
          } else {
            // display email error message in the form field
            let payloadNotify = {
              isToast: true,
              title: "Additional Account Infomation",
              content: "Account Details cannot be saved",
              list: [data.details],
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "Additional Account Infomation",
            content: "Account Details cannot be saved",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isLoading = false;
          this.isInfoSubmitted = true;

          this.setupFeeProps = {
            ...this.setupFeeProps,
            forceDisable: !this.isInfoSubmitted,
          };
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Additional Account Infomation",
          content: "Account Details cannot be saved ",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAdditionalInfo();
        console.log(data);
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.contactNumber = details
            ? data.content.treasurerPhoneNumber
            : "N/A";

          this.isInfoSubmitted = !details.can_update;
          this.canRetain = details.can_retain;
          (this.inputFelidValues.identificationNo = details
            ? details.identificationNo
            : null),
            (this.inputFelidValues.mailingAdress = details
              ? details.campaignMailingAddress
              : null),
            (this.inputFelidValues.contactNo = details
              ? details.treasurerPhoneNumber
              : null),
            (this.declarationName = details ? details.declarationName : null),
            (this.acceptTermAndConditon = details
              ? details.acceptTermAndConditon
              : null),
            (this.statementDeclare = details ? details.statementDeclare : null);
        } else {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async setMinimumValue() {
      try {
        this.isMinValueLoading = true;
        let payload = {
          minimumDonation: this.minValue.slice(1).trim(),
        };

        let { data } = await CreateLayout(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Contribution Value",
            content: "Contribution value changed successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Contribution Value",
            content: `Unable to save Contribution value! ${data ? data.msg : "Please try again."
              }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Contribution Value",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isMinValueLoading = false;
      }
    },

    onKeypressTaxId() {
      var str = this.inputFelidValues.taxId;
      str = str.replace("-", "");
      if (str.length > 2) {
        str = str.substring(0, 2) + "-" + str.substring(2);
      }
      this.inputFelidValues.taxId = str;
    },
    onKeypressIdentification() {
      var str = this.inputFelidValues.identificationNo;
      str = str.replace("C", "");
      if (str.length > 0) {
        str = str.substring(0, 0) + "C" + str.substring(0);
      }
      this.inputFelidValues.identificationNo = str;
    },
    async callInitFn() {
      this.showPwModalKey = Number(new Date()) + 100;
    },
    onKeypressDollorSign() {
      var str = this.minValue;
      str = str.replace("$ ", "");
      if (str.length > 0) {
        str = str.substring(0, 0) + "$ " + str.substring(0);
      }
      this.minValue = str;
    },
  },
};
</script>

<style scoped>
.space {
  margin-bottom: 4px;
}

/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-gap: 6%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
  display: block;
  /* margin-left: 200px;
  margin-right: auto; */
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
  display: block;
  /* margin-left: 250px;
  margin-right: auto; */
}

.edit-btn {
  border: 1px solid;
  width: 35px;
  height: 35px;
  padding: 7px;
}

.col-align {
  width: 110%;
}

.row-divider {
  width: 100%;
  border-top: 1px solid var(--light);
}

.custom-checkbox>>>.custom-control-label::before {
  border-color: var(--light-gray);
}

.form-group>>>select.custom-select {
  border-color: var(--primary);
}

.form-group>>>label {
  color: var(--prime-gray);
  margin-bottom: 10px;
}

.form-group>>>.custom-select {
  color: var(--primary);
}

.custom-checkbox>>>.custom-control-input:checked~.custom-control-label::before {
  background-color: var(--primary);
  border-color: var(--primary);
}

.custom-checkbox>>>.custom-control-input:indeterminate~.custom-control-label::before {
  background-color: var(--white);
  border-color: var(--light-gray);
}

.submitBtn.form-button {
  border-radius: 4px;
  width: 300px;
}

.form-group>>> :checked~label {
  color: var(--primary);
}

/* custom CSS */
.datepicker {
  background-color: transparent;
  z-index: 5;
  border-radius: 4px;
  min-height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  background-color: var(--white);
}

.datepicker>>>label.form-control {
  min-height: 50px;
  box-shadow: none;
  /* calculate the line height by reducing the excess padding */
  line-height: calc(50px - calc(0.375rem * 2));
  color: var(--prime-gray);
}

.datepicker>>>.form-control {
  box-shadow: none;
  /* color: var(--prime-gray) !important; */
}

.date-picker>>>.btn .b-icon.bi {
  color: var(--prime-gray);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: 0 !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: 0 !important;
}

.contact-number-input.form-group>>>input.form-control {
  border-left: 0px solid transparent;
}

.contact-number-input>>>.input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0px solid transparent;
  min-width: 60px;
  margin-left: 0;
}

.form-group.is-focus:disabled>>>label,
.form-group.is-focus.is-disabled>>>label.form-label {
  color: var(--prime-gray);
}

.b-form-btn-label-control.form-control[aria-disabled="true"] {
  background-color: transparent;
}

/* .datepicker >>> label.form-control {
    color: var(--prime-gray);
} */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }

  .footer-img {
    max-width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .version-number {
    text-align: center;
    font-size: 0.7rem;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .setUpBtn {
    margin: 2px;
  }
}
</style>
