export const charity = [];

export const giveProudlyInputFelidsCommon = [
  {
    groupId: "identificationNo-name-group",
    id: "identificationNo-name-group-input",
    class: "text-prime-gray",
    state: null,
    invalidFeedback: "",
    label: "State Identification No.",
    isRequired: true,
    isDisabled: false,
    type: "text",
    name: "identificationNo",
    form: "admin-registration-form",
    trim: true,
  },

  {
    groupId: "mailingAdress-name-group",
    id: "mailingAdress-name-group-input",
    class: "text-prime-gray",
    state: null,
    invalidFeedback: "",
    label: "Charity Physical Address",
    isRequired: true,
    isDisabled: false,
    type: "text",
    name: "mailingAdress",
    form: "admin-registration-form",
    trim: true,
  },
  {
    groupId: "contactNo-name-group",
    id: "contactNo-name-group-input",
    class: "text-prime-gray",
    state: null,
    invalidFeedback: "",
    label: "Treasurer’s Phone No.",
    isRequired: true,
    isDisabled: false,
    type: "text",
    name: "contactNo",
    form: "admin-registration-form",
    trim: true,
  },
];
