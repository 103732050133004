import Instance from "./instance";

export const GetDonorDetails = async () => {
  try {
    return await Instance.instanceToken.get(`user/donor/details`);
  } catch (error) {
    return error;
  }
};

export const ExportCsv = async () => {
  try {
    return await Instance.instanceToken.get(`donors/export`);
  } catch (error) {
    return error;
  }
};

export const GetAllDonors = async (params) => {
  try {
    return await Instance.instanceToken.get(`/donors`, {
      params
    });
  } catch (error) {
    return error;
  }
};

export const GetSingleDonor = async (id) => {
  try {
    return await Instance.instanceToken.get(`/donors/${id}`);
  } catch (error) {
    return error;
  }
};