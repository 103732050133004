<template>
  <div>
    <h6 class="text-left text-dark font-weight-semi-bold font-primary mt-3">
      Aristotle Account
    </h6>

    <b-row v-if="aristotleInfo.message">
      <b-alert class="m-4" show :variant="aristotleInfo.message.type">
        <div v-html="aristotleInfo.message.message"></div>
      </b-alert>
    </b-row>

    <!-- Token is not provided or connection verification failed-->
    <b-container v-if="!aristotleInfo.token_provided || aristotleInfo.connection_status == 'failed'">
      <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
        <!-- v-model="minValue" -->
        <b-col cols="12" v-if="showFormInput">
          <FormInput groupId="Aristotle-name-group" id="Aristotle-name-group-input" class="text-prime-gray"
            label="Aristotle key" v-model="aristotleKey" isRequired trim form="Aristotle-form" type="text"></FormInput>
        </b-col>
        <b-col class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn" cols="12">
          <FormButton id="connect-aristotle" tooltip="Link to your existing Aristotle Campaign Manager account and automatically store Personal PAC donation data in your campaign's Campaign Manager database." :isLoading="submitLoading" variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark" type="submit"
            @click.native="submitToken">
            Connect Aristotle
          </FormButton>
        </b-col>
      </b-row>
    </b-container>

    <!-- Token provided -->
    <!-- Connection status pending -->
    <b-container v-if="aristotleInfo.token_provided && aristotleInfo.connection_status == 'pending'">
      <b-row class="d-flex flex-row align-items-center justify-content-between w-100" no-gutters>
        <!-- v-model="minValue" -->
        <br>
        <FormButton :isDisabled="submitLoading" variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark" type="submit"
          @click.native="connectionStatusUpdate('active')">
          Yes
        </FormButton>
        <FormButton :isDisabled="submitLoading" variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark" type="submit"
          @click.native="connectionStatusUpdate('failed')">
          No
        </FormButton>
        <br>
      </b-row>
    </b-container>

    <!-- Token provided -->
    <!-- Connection status active -->
    <b-container v-if="aristotleInfo.token_provided && aristotleInfo.connection_status == 'active'">
      <b-col class="d-flex flex-column align-items-center justify-content-center px-0 py-2 setUpBtn" cols="12">
          <FormButton :isLoading="submitLoading" variant="primary" isBlock class="font-weight-normal text-white mt-2 border-dark" type="submit"
            @click.native="disconnect">
            Disconnect Aristotle
          </FormButton>
        </b-col>
    </b-container>
    
    <b-row>
      <b-alert v-for="(message, index) in messages" :key="index" class="m-4" show :variant="message.type">
        <div v-html="message.html"></div>
      </b-alert>
    </b-row>

  </div>
</template>

<script>
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";
import {
  AristotleSubmitToken,
  AristotleConnectionStatusUpdate,
  AristotleDisconnect
} from "@/services/settings.service";

export default {
  name: "Aristotle",
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      submitLoading: false,
      messages: [],
      forceMsg: false,
      showDisconnectDirections: false,
      disconnected: false,
      loadingDisconnect: false,
      aristotleKey: "",
      showFormInput: false,
      aristotleInfo: {}
    };
  },
  props: {
    details: {
      type: Object,
      required: true
    },
    forceDisabled: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.aristotleInfo = this.details
    console.log("Aristotle mounted ", this.details)
  },
  computed: {

  },
  methods: {
    async submitToken() {
      if (this.showFormInput && this.aristotleKey.trim() !== "") {
        this.submitLoading = true
        const res = await AristotleSubmitToken(this.aristotleKey)
        this.aristotleInfo = res.data.content
      } else {
        this.showFormInput = !this.showFormInput;
      }
      this.submitLoading = false;

    },
    async connectionStatusUpdate(status) {
      this.submitLoading = true;
      const res = await AristotleConnectionStatusUpdate(status)
      this.aristotleInfo = res.data.content
      this.submitLoading = false;
    },
    async disconnect(status) {
      console.log("Disconnection")
      this.submitLoading = true;
      const res = await AristotleDisconnect(status)
      this.aristotleInfo = res.data.content
      this.submitLoading = false;
    },
  },
};
</script>

<style scoped>
.setUpBtn {
  margin: 2px;
}
</style>