<template>
  <!-- order items modal -->
  <b-modal
    id="edit-account-row-modal"
    ref="EditItemModal"
    title="Edit account details"
    size="md"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          isBlock
          :disabled="isLoading"
          :isLoading="isLoading"
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
          type="button"
          @click.native="deleteFn"
          >Update</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <b-col cols="12">
        <FormInput
          groupId="admin-name-group"
          id="admin-name-group-input"
          class="text-prime-gray"
          :state="nameError.status"
          :invalidFeedback="`${nameError.message}`"
          label="Treasurer’s Full Name"
          v-model="name"
          isRequired
          trim
          form="admin-registration-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <b-col cols="12">
        <FormInput
          groupId="admin-name-group"
          id="admin-name-group-input"
          class="text-prime-gray"
          :state="urlError.status"
          :invalidFeedback="`${urlError.message}`"
          label="Campaign URL"
          v-model="url"
          isRequired
          trim
          form="admin-registration-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <b-col cols="12">
        <FormInput
          groupId="admin-name-group"
          id="admin-name-group-input"
          class="text-prime-gray"
          :state="contactNoError.status"
          :invalidFeedback="`${contactNoError.message}`"
          label="Contact No:"
          v-model="contactNo"
          isRequired
          trim
          form="admin-registration-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
// services
import FormButton from "@/components/Form/FormButton";
// import { DeleteAdmin } from "@/services/admin.service";
import FormInput from "@/components/Form/FormInput";

export default {
  name: "DeleteAdmin",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    // itemIndex: {
    //   default: null,
    //   type: Number,
    // },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      nameError: {
        status: null,
        message: "",
      },
      name: null,
      urlError: {
        status: null,
        message: "",
      },
      url: null,
      contactNoError: {
        status: null,
        message: "",
      },
      contactNo: null,
    };
  },
  components: {
    FormButton,
    FormInput,
  },
  methods: {
    async deleteFn() {},
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
